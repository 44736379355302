import React from 'react';
import { Line } from 'react-chartjs-2';

const LineGraph = ({ data }) => {
  const sortedKeys = Object.keys(data.bias_error)
    .map(Number) // Convert keys to numbers for proper numeric sorting
    .sort((a, b) => a - b); // Sort keys numerically

  const brands = Object.keys(data);
  const colors = {
    bias_error: '#4285f4',
    bias_user_error: '#ed7d31',
  };

  const datasets = brands.map((brand) => ({
    label: brand === "bias_error" ? "Engine Bias" : "Planner Bias",
    data: sortedKeys.map((key) => data[brand][key]), // Map data points based on sorted keys
    fill: false,
    borderColor: colors[brand],
    backgroundColor: colors[brand],
    tension: 0.4,
  }));

  const chartData = {
    labels: sortedKeys.map(String),
    datasets: datasets,
  };

  const options = {
    indexAxis: 'x',
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return Intl.NumberFormat('en-US', {
              notation: "compact",
              maximumFractionDigits: 1
            }).format(value);
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 50,
        bottom: 15,
      },
    },
  };

  return (
    <div className='w-1/2 h-72 flex relative flex-col justify-center items-center bg-white rounded-lg shadow-sm'>
      <div className="flex flex-row absolute items-center justify-end space-x-3 top-4 right-3 w-full">
        <div className="flex flex-row items-center space-x-2 justify-between">
          <div className='h-4 w-12 bg-[#4489e2] rounded-full' />
          <p>Engine Error</p>
        </div>
        <div className="flex flex-row items-center space-x-2 justify-between">
          <div className='h-4 w-12 bg-[#ed7d31] rounded-full' />
          <p>Planner Error</p>
        </div>
      </div>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineGraph;
