import React from 'react'
import { BiSolidBarChartAlt2 } from "react-icons/bi";

const Errors = () => {
    return (
        <div className='w-full h-full flex flex-row gap-2 text-bodyTextBlue'> 
            <div className='flex flex-col w-1/3 p-3 bg-white rounded-xl'>
               <h1 className='font-semibold'>Forecast Bias</h1>
               <p className='text-slate-500'>It assesses whether forecast errors consistently deviate in one direction</p>
            </div>
            <div className='flex flex-col w-1/3 p-3 bg-white rounded-xl'>
               <h1 className='font-semibold'>WMAPE (Weighted Mean Absolute Percentage Error)</h1>
               <p className='text-slate-500'>It is an imporovment over MAPE (Mean absolute percentage error) by weighting it based on actual sales volumes</p>
            </div>
            <div className='flex flex-col w-1/3 p-3 bg-white rounded-xl'>
               <h1 className='font-semibold'>OTIF (On Time In Full)</h1>
               <p className='text-slate-500'>It shows how many orders are deliverd on time in full</p>
            </div>
        </div>
    )
}

export default Errors