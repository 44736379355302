import { Button, DatePicker, Input, Select, Switch, Upload, message } from 'antd';
import React, { useState } from 'react';
import { FaAngleRight } from 'react-icons/fa6';
import ForecastHelper from './ForecastHelper';
import { PiStarFourBold } from 'react-icons/pi';
import { FiSettings } from 'react-icons/fi';
import { usePortfolio } from '../../Layout/baseContext/portfolioContext/PortfolioContext';
import DiscontinueForm from './DiscontinueForm';
import TransitionForm from './TransitionForm';
import AddForm from './AddForm';
import { useBase } from '../../Layout/baseContext/BaseContext';

const PortForm = () => {

    const { uiMappingData } = useBase();

    const { selectedListItem, selectedChip, products, customers, locations, disContinueForm, setDisContinueForm, oldTransitionForm, setOldTransitionForm } = usePortfolio();
    const customerOptions = customers && customers.map(customer => ({
        value: customer.CUSTOMER,
        label: customer.CUSTOMER
    }));
    const productOptions = products && products.map(product => ({
        value: product.ITEM,
        label: product.ITEM
    }));
    const locationOptions = locations && locations.map(location => ({
        value: location.LOCATION,
        label: location.LOCATION
    }));

    return (
        <>
            {
                disContinueForm ?
                    <DiscontinueForm customerOptions={customerOptions} locationOptions={locationOptions} productOptions={productOptions} />
                    :
                    selectedListItem ?
                        <div className='text-bodyTextBlue  flex flex-col h-full px-6 p-4 relative'>
                            <div className='flex justify-between mb-1'>
                                <h1 className='text-lg font-bold capitalize'>
                                    {
                                        selectedChip === 'products' &&
                                        selectedListItem.ITEM
                                    }
                                    {
                                        selectedChip === 'customers' &&
                                        selectedListItem.CUSTOMER
                                    }
                                    {
                                        selectedChip === 'locations' &&
                                        selectedListItem.LOCATION
                                    }
                                </h1>
                                <div className='flex gap-4 items-center'>
                                    <div>
                                        <Switch defaultChecked className='bg-slate-400' />
                                    </div>
                                    <div className='flex items-center pl-2'>
                                        <Button type='link' onClick={() => setOldTransitionForm(true)} className='m-0 p-0 flex items-center'>
                                            <div className='text-xl pr-1'>
                                                <PiStarFourBold />
                                            </div>
                                            <p>Transition from old</p>
                                        </Button>
                                    </div>
                                    <div className='text-xl'>
                                        <Button className='border-0 p-0 text-base flex items-center justify-center' shape="circle" icon={<FiSettings />} />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {
                                oldTransitionForm ?
                                    <TransitionForm locationOptions={locationOptions} customerOptions={customerOptions} productOptions={productOptions} />
                                    :
                                    <AddForm locationOptions={locationOptions} customerOptions={customerOptions} productOptions={productOptions} />
                            }
                        </div>
                        :
                        <div className='text-bodyTextBlue flex flex-col gap-3 h-full text-center justify-center items-center'>
                            <p className='text-3xl pb-2 '>
                                <span>
                                    Click on any item on the left to manage new
                                </span>
                                <span className='px-2'>
                                    {selectedChip === 'products' && uiMappingData[0]['ITEM']}
                                    {selectedChip === 'customers' && uiMappingData[0]['CUSTOMER']}
                                    {selectedChip === 'locations' && uiMappingData[0]['LOCATION']}
                                </span>
                                <span>
                                    or transitions
                                </span>
                            </p>
                            <p className='text-xl '>Or</p>
                            <Button type='link' onClick={() => setDisContinueForm(true)}>
                                <p className='text-3xl underline decoration-1 underline-offset-4'>
                                    <span>
                                        Click here if you want to discontinue a
                                    </span>
                                    <span className='px-2'>
                                        {selectedChip === 'products' && uiMappingData[0]['ITEM']}
                                        {selectedChip === 'customers' && uiMappingData[0]['CUSTOMER']}
                                        {selectedChip === 'locations' && uiMappingData[0]['LOCATION']}
                                    </span>
                                </p>
                            </Button>
                        </div>
            }

        </>
    );
};

export default PortForm;
