import React from 'react'
import { FaAngleLeft } from "react-icons/fa6";
import DiscussionThread from '../components/DiscussionThread';
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import KeyInsights from '../components/KeyInsights';
import { Button } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import FormContainer from '../components/FormContainer';

const Discussion = ({ setDiscussion }) => {
    const { discussionLoading, selectedPost } = usePost();

    const dateOnly = selectedPost && selectedPost.UPDATED_AT.split('T')[0];
    return (
        <>

            {
                selectedPost &&
                <div className='w-full flex flex-col bg-white rounded-xl h-full p-4 pb-8 text-bodyTextBlue shadow-sm'>
                    <div className='text-xs flex justify-between border-b border-slate-200 pb-2'>
                        <button onClick={() => setDiscussion(false)}>
                            <Button size='small' type='link' className='flex items-center gap-1 text-gray-400 m-0 p-0'>
                                <span><FaAngleLeft /></span>
                                <span>Go Back</span>
                            </Button>
                        </button>
                        <div className='flex gap-3 text-gray-400'>
                            <div>
                                <p>Status: <span className='text-secondary font-semibold capitalize'>{selectedPost.status} Discussion</span></p>
                            </div>
                            <div>
                                <p>Last Edits Made: <span className='text-secondary font-semibold'>{dateOnly}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4 h-full '>
                        <div className='flex-grow h-full '>
                            {
                                // discussionLoading ?
                                //     (<div className='h-full flex justify-center items-center'><TailSpin
                                //         visible={true}
                                //         height="40"
                                //         width="40"
                                //         color="#2B3674"
                                //         ariaLabel="tail-spin-loading"
                                //         radius="1"
                                //         wrapperStyle={{}}
                                //         wrapperClass=""
                                //     /></div>)
                                //     :
                                    (<DiscussionThread postDiscussion={selectedPost} />)
                            }
                        </div>
                        <div className='w-[20rem] shrink-0 h-full flex flex-col justify-between gap-6 overflow-y-scroll '>
                            <div className='flex-grow w-full '>
                                <KeyInsights />
                            </div>
                            <div className='border border-blue-300 rounded-lg'>
                                <FormContainer />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default Discussion