import React from 'react';
import { DashboardProvider } from './dashboardContext/DashboardContext'; // Adjust the import path accordingly
import { PostProvider } from './postContext/PostContext';
import { PortfolioProvider } from './portfolioContext/PortfolioContext';
import { UploadProvider } from './uploadContext/UploadContext';
import { AlertProvider } from './alertsContext/AlertsContext';
import { PlanningProvider } from './planningContext/PlanningContext';
import { SupplyProvider } from './supplyContext/supplyContext';
// Import other context providers

const providers = [
  DashboardProvider,
  PostProvider,
  PortfolioProvider,
  UploadProvider,
  AlertProvider,
  PlanningProvider,
  SupplyProvider
  // Add other providers here
];

const CombinedContextProvider = ({ children }) => {
  return providers.reduce((Combined, Provider) => {
    return ({ children }) => (
      <Combined>
        <Provider>{children}</Provider>
      </Combined>
    );
  }, ({ children }) => <>{children}</>)({ children });
};

export default CombinedContextProvider;
