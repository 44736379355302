import React from 'react'

import { PiChatCircleBold } from "react-icons/pi";
import { TbVideo } from "react-icons/tb";
import FormContainer from './FormContainer';
import { Button } from 'antd';


const ModificationForm = () => {
    return (
        <div className='bg-slate-100 mt-4 rounded-xl '>
            <div className='flex '>
                <FormContainer/>
                <div className='basis-1/2 flex flex-col justify-center items-center text-center border-l border-slate-300 my-3'>
                    <div className='text-gray-400 text-xs'>
                        <p>Or</p>
                        <p>Initiate a collaboration via</p>
                        <div className='flex flex-wrap gap-x-4 gap-y-2 mt-2 justify-center text-secondary font-semibold px-4 '>
                            <Button type='link' className='flex items-center gap-1 text-xs p-0'>
                                <p className='text-lg'><PiChatCircleBold /></p>
                                <p>Start a new thread</p>
                            </Button>
                            <Button type='link' className='flex items-center gap-1 text-xs p-0'>
                                <p className='text-lg'><TbVideo /></p>
                                <p>Start a new meeting</p>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModificationForm