import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarGraph = ({ data }) => {
  
  const dates = data && Object.keys(data);
  const brands = data && Object.keys(data[dates[0]]); 

  const colors = {
    bias: '#4285f4',
    bias_user: '#ed7d31',
    ape: '#4285f4',
    ape_user: '#ed7d31',
    mape: '#4285f4',
    mape_user: '#ed7d31',
    // Add more colors as needed
  };

  const datasets = brands && brands.map((brand) => {
    const x = brand.split('_');
    const label = x[1] !== "user" ? "Engine" : "Planner";
  
    return {
      barPercentage: 0.8,
      categoryPercentage: 0.5,
      label: label,
      data: dates.map((date) => data[date][brand]),
      backgroundColor: colors[brand],
      borderRadius: 15,
      borderWidth: 0,
      barThickness: 12,
    };
  });
  

  const chartData = {
    labels: dates,
    datasets: datasets,
  };

  const options = {
    indexAxis: 'x',
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Remove gridlines on the y-axis
        },
        ticks: {
          callback: function (value) {
            return value + ' %'; // Add percentage symbol to y-axis labels
          },
        },
      },
      x: {
        grid: {
          display: false, // Remove gridlines on the x-axis
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
  };

  return (
    <div className='w-1/2 p-4 h-72 flex flex-col justify-center items-center bg-white rounded-lg shadow-sm'>
      <div className="flex flex-row items-center justify-end space-x-3 w-full">
        <div className="flex flex-row items-center space-x-2 justify-between">
          <div className='h-4 w-12 bg-[#4489e2] rounded-full'/>
          <p>Engine</p>
        </div>
        <div className="flex flex-row items-center space-x-2 justify-between">
          <div className='h-4 w-12 bg-[#ed7d31] rounded-full'/>
          <p>Planner</p>
        </div>
      </div>
      {
        data && <Bar width='800px' height='300px' data={chartData} options={options} />

      }
    </div>
  );
};

export default BarGraph;
