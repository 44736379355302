import React, { useState, useRef, useEffect } from 'react';
import { PiPulseBold } from "react-icons/pi";
import { Input, Button } from "antd";
import chatDefaultLogo from "../../../components/assets/img/defaultActivity.png";
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import moment from 'moment';
import api from '../../../api';

const DiscussionThread = ({ postDiscussion }) => {
    const [newComment, setNewComment] = useState('');
    const username = localStorage.getItem("username");
    const userid = localStorage.getItem("userid")
    const { comments, selectedPost, fetchComments, postingComment ,setPostingComment } = usePost();
    const commentsContainerRef = useRef(null);

    const handleInputChange = (e) => {
        setNewComment(e.target.value);
    };

    const generateActivityId = () => {
        return moment().format('YYYYMMDDHHmmssSSS'); // Format: YYYYMMDDHHmmssSSS (milliseconds included)
      };
      const ACTIVITY_ID = generateActivityId();
    const handlePostComment = async () => {
        const commentData = {
            POST_ID: selectedPost.id,
            COMMENT_ID: Date.now().toString(),
            COMMENT: newComment,
            BY_USER: username || "null",
            BY_ROLE: "null",
            CREATED_AT: new Date().toISOString(),
            ACTIVITY_ID
        };

        setPostingComment(true)
        try {

               const response = await api.post("/api/comments/", commentData);


            if (response.status === 200 || response.status === 201) {
                setNewComment('');
                await fetchComments(true);
            } else {
                console.error('Failed to post comment');
            }
        } catch (error) {
            console.error('Error posting comment:', error);
        }
        setPostingComment(false)
    };

    useEffect(() => {
        if (commentsContainerRef.current) {
            commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
    }, [comments]);

    return (
        <div className='flex flex-col text-xs pl-2 pt-2 h-full pb-4'>
            <h2 className='font-semibold flex gap-2 items-center text-sm'>
                <span><PiPulseBold /></span>
                <span>Discussion Thread</span>
            </h2>
            <p className='mt-2 pb-2'>
                <span className='text-primary font-semibold'>{postDiscussion.BY_ROLE}</span>
                <span className='pl-2'>{`User ${postDiscussion.BY_USER} adjusted ${postDiscussion.PREVIOUS_VALUE} to ${postDiscussion.NEW_VALUE} at ${postDiscussion.ITEM_LEVEL}-${postDiscussion.LOCATION_LEVEL}-${postDiscussion.CUSTOMER_LEVEL} with values ${postDiscussion.ITEM_VALUE}-${postDiscussion.LOCATION_VALUE}-${postDiscussion.CUSTOMER_VALUE} at ${postDiscussion.TIME_LEVEL} for ${postDiscussion.TIME_VALUE} with an initial comment ${postDiscussion.INITIAL_COMMENT}`}</span>
            </p>
            <hr />
            <p className='text-gray-400 mt-2'>Previous comments</p>
            <div className='mt-2 flex flex-col h-full relative'>
                <div className='absolute flex flex-col gap-2 w-full h-full justify-between'>
                    <div
                        className='comments-container flex flex-col gap-2 text-black w-full h-full overflow-y-auto scroll px-2'
                        ref={commentsContainerRef}
                    >
                        {
                            comments.length === 0 ? 
                            (<div className='flex justify-center items-center h-full'>
                                <h3 className='font-bold text-lg text-slate-300'>No comments yet</h3>
                            </div>)
                            :
                        comments.map((comment, index) => (
                            comment.BY_USER === username ? (
                                <div className='sent flex justify-end ml-20' key={index}>
                                    <div className='flex flex-col bg-indigo-100 p-2 w-fit rounded-lg'>
                                        <div className='flex gap-2 items-center'>
                                            <img className='rounded-full' src={chatDefaultLogo} width={20} alt="" />
                                            <span className='font-bold'>{comment.BY_USER}</span>
                                        </div>
                                        <p className='mt-1 text-slate-500'>{comment.COMMENT}</p>
                                    </div>
                                </div>
                            ) : (
                                <div className='received flex justify-start mr-20 rounded' key={index}>
                                    <div className='flex flex-col bg-slate-100 p-2 w-fit rounded-lg'>
                                        <div className='flex gap-2 items-center'>
                                            <img className='rounded-full' src={chatDefaultLogo} width={20} alt="" />
                                            <span className='font-bold'>{comment.BY_USER}</span>
                                        </div>
                                        <p className='mt-1 text-slate-500'>{comment.COMMENT}</p>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                    <div>
                        <div>
                            <Input
                                type='text'
                                placeholder='Share your thoughts'
                                value={newComment}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='flex justify-between mt-2'>
                            <div className='flex gap-2'>
                                <button className='bg-slate-100 p-1 px-2 rounded-md font-semibold text-red-500 hover:bg-slate-200'>Raise Concern</button>
                                <button className='bg-slate-100 p-1 px-2 rounded-md font-semibold text-green-500 hover:bg-slate-200'>Approve changes</button>
                            </div>
                            <div className='flex gap-2 relative'>
                                <Button type='primary' loading={postingComment} iconPosition='end' className='bg-blue-500' onClick={handlePostComment}>Post</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DiscussionThread;
