import React, { useState } from 'react';
import { usePortfolio } from '../../Layout/baseContext/portfolioContext/PortfolioContext';
import PortListItem from './PortListItem';
import { TailSpin } from 'react-loader-spinner';
import { FiSearch } from 'react-icons/fi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useBase } from '../../Layout/baseContext/BaseContext';

const PortList = () => {
    const { selectedChip, setSelectedChip, dataChanger, selectedTab, data, loading } = usePortfolio();
    const [searchQuery, setSearchQuery] = useState('');
    const {uiMappingData} = useBase();

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = data && data.filter(item => {
        if (!searchQuery) return true;
        const itemString = JSON.stringify(item).toLowerCase();
        return itemString.includes(searchQuery.toLowerCase());
    });

    return (
        <div className='rounded-2xl text-bodyTextBlue h-full flex flex-col '>
            {loading ? (
                <div className='h-full flex justify-center items-center'>
                    <TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color="#2B3674"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            ) : (
                <div className='flex flex-col h-full'>
                    <div className='flex bg-white rounded-full gap-2 p-[0.35rem] shadow-sm text-gray-400 w-full'>
                        <button className={`${selectedChip === "products" ? "bg-secondary text-white rounded-full " : "hover:text-secondary"} px-2 py-1 flex-1  whitespace-nowrap`} onClick={() => { setSelectedChip("products"); dataChanger('products') }}>{uiMappingData[0]['ITEM']}</button>
                        <button className={`${selectedChip === "locations" ? "bg-secondary text-white rounded-full " : "hover:text-secondary"} px-2 py-1 flex-1 whitespace-nowrap`} onClick={() => { setSelectedChip("locations"); dataChanger('locations') }}>{uiMappingData[0]['LOCATION']}</button>
                        <button className={`${selectedChip === "customers" ? "bg-secondary text-white rounded-full " : "hover:text-secondary"} px-2 py-1 flex-1 whitespace-nowrap`} onClick={() => { setSelectedChip("customers"); dataChanger('customers') }}>{uiMappingData[0]['CUSTOMER']}</button>
                    </div>
                    <div className='bg-white h-full flex flex-col shadow-sm rounded-2xl mt-2'>
                        {/* <div className='flex justify-between items-center px-2 pt-2 pb-2 mx-4'>
                        <h2 className='font-bold text-lg'>
                            {
                                selectedChip === "products" && "Products"
                            }
                            {
                                selectedChip === "customers" && "Customers"
                            }
                            {
                                selectedChip === "locations" && "Locations"
                            }
                        </h2>
                       
                    </div> */}
                        <div className=' border-b flex justify-between text-xs mx-4 font-semibold pt-4'>
                            <p
                                className={`${selectedTab === 'ongoing' ? 'border-b-2 border-bodyTextBlue text-bodyTextBlue' : 'text-inactiveText'
                                    } w-[30%] text-center pb-1 cursor-pointer`}
                            >
                                New profiles
                            </p>
                            <p
                                className={`${selectedTab === 'old' ? 'border-b-2 border-bodyTextBlue text-bodyTextBlue' : 'text-inactiveText'
                                    } w-[30%] text-center pb-1 cursor-pointer`}
                            >
                                Old profiles
                            </p>
                            <p
                                className={`${selectedTab === 'archived' ? 'border-b-2 border-bodyTextBlue text-bodyTextBlue' : 'text-inactiveText'
                                    } w-[30%] text-center pb-1 cursor-pointer`}
                            >
                                All profiles
                            </p>
                        </div>
                        <div className='bg-slate-100 flex mx-4 items-center rounded-full px-3 py-2 mt-2'>
                            <div className='text-secondary'>
                                <FiSearch />
                            </div>
                            <input
                                className='bg-slate-100 outline-none pl-2'
                                type="text"
                                name="Search"
                                placeholder='search'
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </div>
                        <div className='relative h-full w-full'>

                            <div className='absolute h-full flex flex-col w-full'>

                                <ul className='scroll flex flex-col gap-2 overflow-y-scroll p-3 h-full'>
                                    {filteredData && filteredData.length === 0 ? (
                                        <div className='flex flex-col justify-center items-center mt-10 text-slate-400'>
                                            <div className='text-4xl'>
                                                <RiErrorWarningLine />
                                            </div>
                                            <p className='font-bold'>No data found</p>
                                        </div>
                                    ) : (
                                        <>
                                            {filteredData && filteredData.map((item, index) => (
                                                <li className='w-full' key={index}>
                                                    <PortListItem item={item} />
                                                </li>
                                            ))}
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PortList;
