import React from 'react'

const ParamCards = ({ label, setSelectedLabel }) => {
  return (
    <div onClick={() => setSelectedLabel(label)} className=' bg-white flex flex-col rounded-md  border p-4 shadow-md cursor-pointer  hover:bg-slate-100'>
      <p className='text-base font-semibold'>
        {label}
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis libero, eveniet culpa praesentium doloribus illo!
      </p>
    </div>
  )
}

export default ParamCards