import React from 'react';
import { Button, message, Upload } from 'antd';
import { Tooltip } from 'react-tooltip'
import { FiSettings } from 'react-icons/fi';
import { useUpload } from '../../../Layout/baseContext/uploadContext/UploadContext';
import { GrConfigure } from "react-icons/gr";
import { IoMdArrowRoundBack } from 'react-icons/io';
import Templates from './Templates';
import * as XLSX from 'xlsx';
import { useBase } from '../../../Layout/baseContext/BaseContext';
import api from '../../../../api';

const UploadDoc = ({setFirstPage, setConfigurePage}) => {
    const { categories, selectedCategory, setselectedCategory, setConfigBox } = useUpload();

    // const props = {
    //     name: 'file',
    //     onChange(info) {
    //         const { status } = info.file;
    //         if (status !== 'uploading') {
    //             console.log(info.file, info.fileList);
    //         }
    //         if (status === 'done') {
    //             message.success(`${info.file.name} file uploaded successfully.`);
    //         } else if (status === 'error') {
    //             message.error(`${info.file.name} file upload failed.`);
    //         }
    //     },
    //     onDrop(e) {
    //         console.log('Dropped files', e.dataTransfer.files);
    //     },
    //     customRequest({ file, onSuccess }) {
    //         setTimeout(() => {
    //             onSuccess("ok");
    //         }, 1000);
    //     }
    // };
    const props = {
        name: 'file',
        customRequest({ file, onSuccess, onError }) {
            const formData = new FormData();
            formData.append('file', file); // Append the file to the FormData object
    
            api.post('/api/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (response.data.errors) {
                    message.error('File upload failed due to validation errors.');
                    console.log('Validation errors:', response.data.errors);
                    onError(response.data.errors); // Trigger the error callback if needed
                } else {
                    message.success('File uploaded and processed successfully.');
                    onSuccess('ok'); // Trigger the success callback
                }
            })
            .catch((error) => {
                console.error('Upload error:', error);
                message.error('File upload failed.');
                onError(error); // Trigger the error callback
            });
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        }
    };

    const handleHome = () => {
        setselectedCategory(null);
        setFirstPage(true)
    }

    const handleConfig = (cat) => {
        setConfigurePage(true)
        setConfigBox(cat)
    }


    const { uiMappingData } = useBase();
    
    // Initialize the output object
    const formattedData = {
        "Item": [],
        "Location": [],
        "Customer": []
    };

    // Iterate over the data object
    for (const key in uiMappingData[0]) {
        if (key.startsWith('I')) {
            formattedData["Item"].push(uiMappingData[0][key]);
        } else if (key.startsWith('L')) {
            formattedData["Location"].push(uiMappingData[0][key]);
        } else if (key.startsWith('C')) {
            formattedData["Customer"].push(uiMappingData[0][key]);
        }
    }

     // Function to download an Excel file
     const onDownload = (masterType) => {
        const dataToExport = formattedData[masterType];

        // Prepare the data (just one row as requested)
        const worksheetData = [dataToExport];

        // Create a new worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, masterType);

        // Export the Excel file
        XLSX.writeFile(workbook, `${masterType}.xlsx`);
    };

    return (
        <div className='flex flex-col  w-full  items-center min-h-screen text-xs text-bodyTextBlue '>
            <div className='flex justify-between  w-full items-center'>
                <div className='flex gap-6 items-center'>
                <Button onClick={() => handleHome()} className='p-0 text-base text-slate-400' type='link'><IoMdArrowRoundBack/>Go back</Button>

                    {
                        Object.entries(categories).map((cat) => (
                            <div onClick={() => setselectedCategory(cat)} className={` py-1 ${selectedCategory[0] === cat[0] ? "text-blue-500 border-b-2 border-blue-500" : ""} cursor-pointer text-lg`}>{cat[0]}</div>
                        ))
                    }
                </div>
                <div className='text-base font-bold'>
                    <Button type='primary'>Download All Templates</Button>
                </div>
            </div>
            <div className='flex flex-wrap w-full gap-4 mt-8'>
                {
                    selectedCategory[1].map((cat, index) => {
                        return (
                            <div key={index} className='p-4 bg-white shadow-md flex flex-col gap-4 rounded-lg'>
                                <div className='flex text-lg items-center justify-between'>
                                    <p className='text-base font-semibold'>{cat}</p>
                                    <p onClick={() => handleConfig(cat)} className='cursor-pointer hover:text-blue-500' data-tooltip-id="my-tooltip" data-tooltip-content="Configure"><GrConfigure /></p>
                                </div>
                                <div className='flex justify-between items-center gap-4'>
                                    <Button onClick={() => onDownload(cat)} type='link' className='p-0 m-0'>Download Template</Button>
                                    <Upload {...props} >
                                        <Button className='border-blue-500' type='link'>Upload Data</Button>
                                    </Upload>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Tooltip id="my-tooltip" />
            <div className='hidden'>
                <Templates formattedData={formattedData}/>
            </div>
        </div>
    );
};

export default UploadDoc;
