import { Button, DatePicker, Input, Select, Upload } from 'antd';
import { message } from "antd";
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FaAngleRight } from 'react-icons/fa6';
import ForecastHelper from './ForecastHelper';
import { usePortfolio } from '../../Layout/baseContext/portfolioContext/PortfolioContext';
import dayjs from 'dayjs';
import { useBase } from '../../Layout/baseContext/BaseContext';
import api from '../../../api';
import { useDashboard } from '../../Layout/baseContext/dashboardContext/DashboardContext';




const AddForm = ({ locationOptions, customerOptions, productOptions }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [forecastFields, setForecastFields] = useState(Array(12).fill(null));

  const { selectedChip, setSelectedListItem, selectedListItem } = usePortfolio();
  const { fetchActivities } = useDashboard();

  const [formData, setFormData] = useState({
    item: selectedChip === 'products' ? selectedListItem.ITEM : null,
    location: selectedChip === 'locations' ? selectedListItem.LOCATION : null,
    customer: selectedChip === 'customers' ? selectedListItem.CUSTOMER : null,
    startDate: null,
    type: null,
    autoForecastSDate: null,
    avgQuantity: null,
    metrics: Array(12).fill(null),
  });
  useEffect(() => {
    setFormData({
      item: selectedChip === 'products' ? selectedListItem?.ITEM : null,
      location: selectedChip === 'locations' ? selectedListItem?.LOCATION : null,
      customer: selectedChip === 'customers' ? selectedListItem?.CUSTOMER : null,
      startDate: null,
      type: null,
      autoForecastSDate: null,
      avgQuantity: null,
      metrics: Array(12).fill(null),
    });
  }, [selectedChip, selectedListItem]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const newFormData = { ...prev, [name]: value };
      return newFormData;
    });
  };

  const handleSelectChange = (name, value) => {
    setFormData((prev) => {
      const newFormData = { ...prev, [name]: value, avgQuantity: null };
      return newFormData;
    });

    if (name === 'type') {
      setForecastFields(Array(12).fill(null));
    }
  };

  const handleDateChange = (dateString, field) => {
    setFormData((prev) => {
      const newFormData = { ...prev, [field]: dateString };
      return newFormData;
    });
  };

  const handleForecastFieldChange = (index, value) => {
    const newFields = [...forecastFields];
    newFields[index] = value;
    setForecastFields(newFields);
  };

  const handlePaste = (e, setFields, startIndex) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('Text');
    const values = pasteData.split(/[\t\n]+/);
    setFields((prev) => {
      const newFields = [...prev];
      values.forEach((value, index) => {
        if (startIndex + index < newFields.length) {
          newFields[startIndex + index] = value;
        }
      });
      return newFields;
    });
  };

  const userID = localStorage.getItem("userid");

  const handleSubmit = async () => {

    if(formData.startDate === null){
      messageApi.warning("Please select start date")
      return
    }
    if(formData.autoForecastSDate === null){
      messageApi.warning("Please select Automatic forecast start date")
      return
    }
    if(formData.type === null){
      messageApi.warning("Please select Type")
      return
    }
    if(formData.type === 'percentage' && formData.avgQuantity === null){
      messageApi.warning("Please select Average forecast number")
      return
    }
    // Function to generate activity ID using current datetime with milliseconds
    const generateActivityId = () => {
      return moment().format('YYYYMMDDHHmmssSSS'); // Format: YYYYMMDDHHmmssSSS (milliseconds included)
    };

    const startDate = moment(formData.startDate);
    const metrics = {};

    forecastFields.forEach((value, index) => {
      const dateKey = startDate.clone().add(index, 'months').format('YYYY-MM-DD');
      metrics[dateKey] = value;
    });

    // Generate the activity ID
    const activityId = generateActivityId();

    const finalFormData = {
      ...formData,
      metrics,
      userID,
      activityID: activityId, // Add activity ID to the form data
    };

    try {

      const response = await api.post("/api/u1_new_profile/", finalFormData);
      // Axios treats any non-2xx status code as an error
      if (response.status === 401) {
        messageApi.error("Token Expired, Reload the page");
      }
      else {

        fetchActivities()
        setFormData({
          item: null,
          location: null,
          customer: null,
          startDate: null,
          type: null,
          autoForecastSDate: null,
          avgQuantity: null,
          metrics: Array(12).fill(null),
        });

        // Optionally, reset forecastFields if it's part of the state
        setForecastFields(Array(12).fill(null));
        messageApi.success("Added succesfully")

      }


    } catch (error) {
      messageApi.error("Something went wrong")
      console.log(error);
    }
  };




  const { uiMappingData } = useBase()

  const itemList = Object.keys(uiMappingData[0]).filter((item) => item.toString().substring(0, 1) === "I" && uiMappingData[0][item] !== null)
  const locationList = Object.keys(uiMappingData[0]).filter((item) => item.toString().substring(0, 1) === "L" && uiMappingData[0][item] !== null)
  const customerList = Object.keys(uiMappingData[0]).filter((item) => item.toString().substring(0, 1) === "C" && uiMappingData[0][item] !== null)




  return (
    <form className='flex flex-col h-full justify-between w-full'>
      {contextHolder}
      <div className='h-[calc(100%-6rem)] overflow-y-scroll w-full'>
        <div className='text-gray-400 mt-4'>
          <p>
            {selectedChip === 'products' && (
              `You are using the data of ${uiMappingData[0]['ITEM']} ${selectedListItem.ITEM} to create a new ${uiMappingData[0]['ITEM']}`
            )}
            {selectedChip === 'customers' && (
              `You are using the data of ${uiMappingData[0]['CUSTOMER']} ${selectedListItem.CUSTOMER} to create a new ${uiMappingData[0]['CUSTOMER']}`
            )}
            {selectedChip === 'locations' && (
              `You are using the data of ${uiMappingData[0]['LOCATION']} ${selectedListItem.LOCATION} to create a new ${uiMappingData[0]['LOCATION']}`
            )}
          </p>


        </div>
        <details open>
          <summary className='flex gap-1 items-center text-md font-semibold mt-3'>
            <p>Basic Details</p>
            <p className='flex-grow border-b border-gray-300'></p>
            <p><FaAngleRight /></p>
          </summary>
          <div className='transition-all duration-4 ease-in-out mt-4 px-4 text-inactiveText grid grid-cols-3 gap-4 items-center'>
            <div className='flex flex-col gap-1 justify-between'>

              {selectedChip === 'products' ? (
                <>
                  <label htmlFor="item">{uiMappingData[0]['ITEM']}</label>
                  <Input
                    name="item"
                    value={formData.item}
                    // onChange={handleInputChange}
                    placeholder="Enter title"
                    className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'
                  />
                </>
              ) : (
                <>
                  <select className='outline-none cursor-pointer hover:text-blue-500'>
                    {itemList.map((item, index) => (
                      <option key={index} value={item} className='text-inactiveText'>
                        {uiMappingData[0][item]}
                      </option>
                    ))}
                  </select>
                  <Select
                    className='selectTag'
                    showSearch
                    placeholder="Select"
                    style={{
                      height: '2.5rem',
                      borderRadius: '2rem',
                      margin: '0',
                    }}
                    value={formData.item}
                    onChange={(value) => handleSelectChange('item', value)}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={productOptions}
                  />
                </>
              )}
            </div>
            <div className='flex flex-col gap-1 justify-between'>

              {selectedChip === 'locations' ? (
                <>
                  <label htmlFor="item">{uiMappingData[0]['LOCATION']}</label>

                  <Input
                    name="location"
                    value={formData.location}
                    // onChange={handleInputChange}
                    placeholder="Enter location"
                    className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'
                  />
                </>
              ) : (
                <>
                  <select className='outline-none cursor-pointer hover:text-blue-500'>
                    {locationList.map((item, index) => (
                      <option key={index} value={item} className='text-inactiveText'>
                        {uiMappingData[0][item]}
                      </option>
                    ))}
                  </select>
                  <Select
                    className='selectTag'
                    showSearch
                    placeholder="Select"
                    style={{
                      height: '2.5rem',
                      borderRadius: '2rem',
                      margin: '0',
                    }}
                    value={formData.location}
                    onChange={(value) => handleSelectChange('location', value)}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={locationOptions}
                  />
                </>
              )}
            </div>
            <div className='flex flex-col gap-1 justify-between'>

              {selectedChip === 'customers' ? (
                <>
                  <label htmlFor="item">{uiMappingData[0]['CUSTOMER']}</label>
                  <Input
                    name="customer"
                    value={formData.customer}
                    // onChange={handleInputChange}
                    placeholder="Enter customer"
                    className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'
                  />
                </>
              ) : (
                <>
                  <select className='outline-none cursor-pointer hover:text-blue-500'>
                    {customerList.map((item, index) => (
                      <option key={index} value={item} className='text-inactiveText'>
                        {uiMappingData[0][item]}
                      </option>
                    ))}
                  </select>
                  <Select
                    className='selectTag'
                    showSearch
                    style={{
                      height: '2.5rem',
                      borderRadius: '2rem',
                      margin: '0',
                    }}
                    placeholder="Select"
                    value={formData.customer}
                    onChange={(value) => handleSelectChange('customer', value)}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={customerOptions}
                  />
                </>
              )}
            </div>
            <div className='flex flex-col gap-1 justify-between'>
              <label htmlFor="startDate">Start Date</label>
              <DatePicker
                className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'
                value={(formData.startDate) ? dayjs(formData.startDate) : null}
                onChange={(date, dateString) => handleDateChange(dateString, 'startDate')}
              />
            </div>

            <div className='flex flex-col gap-1 justify-between'>
              <label htmlFor="autoForecastSDate">Automatic Forecast Start Date</label>
              <DatePicker
                className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'
                value={(formData.autoForecastSDate) ? dayjs(formData.autoForecastSDate) : null}
                onChange={(date, dateString) => handleDateChange(dateString, 'autoForecastSDate')}
              />
            </div>

            <div className='flex flex-col gap-1 justify-between'>
              <label htmlFor="type">Type (Set up by)</label>
              <Select
                className='selectTag'
                placeholder="Select"
                style={{
                  height: '2.5rem',
                  borderRadius: '2rem',
                  margin: '0',
                }}
                value={formData.type}
                onChange={(value) => handleSelectChange('type', value)}
                options={[
                  { value: 'dates', label: 'Dates' },
                  { value: 'manual', label: 'Manual numbers' },
                  { value: 'percentage', label: 'Percentage' },
                ]}
              />
            </div>

            {formData.type === 'percentage' && (
              <div className='flex flex-col gap-1 justify-between'>
                <label htmlFor="avgQuantity">Average Forecast Number</label>
                <Input
                  name="avgQuantity"
                  value={formData.avgQuantity}
                  onChange={handleInputChange}
                  placeholder="Enter average forecast number"
                  className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'
                />
              </div>
            )}
          </div>
        </details>

        {formData.type === 'dates' && (
          <div className='mt-5 px-4 dates-selected-div'>
            <ForecastHelper />
          </div>
        )}

        {(formData.type === 'manual' || formData.type === 'percentage') && (
          <details open className={`pt-4 h-full flex flex-col mb-5 `}>
            <summary className='flex gap-1 items-center text-md font-semibold mt-2'>
              <p>Forecast Details</p>
              <p className='flex-grow border-b border-gray-300'></p>
              <p><FaAngleRight /></p>
            </summary>
            <div className='h-[calc(100%-2rem)] flex flex-col pt-4 px-4 '>
              <p>Enter Manually below <span className='pl-2'>Or</span>
                <span>
                  <Upload maxCount={1}>
                    <Button type='link'><p className='underline'>Click to Upload</p></Button>
                  </Upload>
                </span>
              </p>
              <div className='h-full flex flex-col pt-3'>
                <div className='flex h-[25rem] gap-8'>
                  <div className='h-full basis-1/3 shrink-0 flex flex-col gap-4 overflow-y-scroll pr-2'>
                    {forecastFields.map((field, index) => (
                      <div className='flex gap-3 items-center' key={index}>
                        <label className='basis-[2rem]' htmlFor={`forecastField-${index + 1}`}>B{index + 1}</label>
                        <Input
                          name={`forecastField-${index + 1}`}
                          value={field}
                          onChange={(e) => handleForecastFieldChange(index, e.target.value)}
                          onPaste={(e) => handlePaste(e, setForecastFields, index)}
                          placeholder={`Enter ${formData.type === 'manual' ? 'number' : 'percentage'}`}
                          className='rounded-md m-0 bg-slate-100 py-2 border-blue-100 w-full'
                        />
                      </div>
                    ))}
                  </div>
                  <div className=' h-full basis-2/3 flex flex-col'>
                    <ForecastHelper />
                  </div>
                </div>
              </div>
            </div>
          </details>
        )}
      </div>

      <div className='flex justify-between items-center  absolute bottom-4 w-full pr-14'>
        <div>
          <Button type='link'>Reset form</Button>
        </div>
        <div className='flex items-center'>
          <div>
            <Button type='link' onClick={() => setSelectedListItem(null)}>Cancel</Button>
          </div>
          <div>
            <Button type='primary' className='bg-blue-500' onClick={handleSubmit}>Submit</Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddForm;
