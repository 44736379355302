import React from 'react'
import { HiOutlineLightBulb } from "react-icons/hi";
import { RxLightningBolt } from "react-icons/rx";


const KeyInsights = () => {
  return (
    <div className='bg-slate-100 mt-3 p-3 rounded-lg h-full'>
        <h2 className='flex gap-2 items-center font-bold'>
          <span className='text-2xl'><HiOutlineLightBulb/></span>
          <span>Key Insights</span>
        </h2>
        <div className='text-xs mt-2 flex flex-col gap-2'>
          <div className='flex items-center bg-white p-2 rounded-md text-secondary'>
            <p className='text-base pr-1'><RxLightningBolt/></p>
            <p>Historical Forecast Value Add is 10% on an Average</p>
          </div>
          <div className='flex items-center bg-white p-2 rounded-md text-green-500'>
            <p className='text-base pr-1'><RxLightningBolt/></p>
            <p>Customer Service Levels were always on Target when adjustment up to +30% were made</p>
          </div>
          <div className='flex items-center bg-white p-2 rounded-md text-red-500'>
            <p className='text-base pr-1'><RxLightningBolt/></p>
            <p>There were historical capacity constraints when the demand was adjusted more than 40%</p>
          </div>
        </div>
    </div>
  )
}

export default KeyInsights