import React, { useState } from 'react'
import { useBase } from '../../../Layout/baseContext/BaseContext';
import { Button } from 'antd';

const UITable = ({ tableName, tableData }) => {
    const { uiMappingData } = useBase();
    const [editSection, seteditSection] = useState(null);
    const [formData, setFormData] = useState({});

    // Check if the section should be editable
    const isEditable = (data) => {
        if (!editSection) return false;
        return editSection.startsWith('I') && data.startsWith('I')
            || editSection.startsWith('L') && data.startsWith('L')
            || editSection.startsWith('C') && data.startsWith('C');
    };

    // Handle changes to the input fields
    const handleInputChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    // Save function
    const handleSave = () => {
        console.log('Form Data:', formData); 
        seteditSection(null); // Close the edit section after saving
    };

    return (
        <div className='flex flex-col border bg-white rounded-md shadow-sm'>
            <header className='flex w-full'>
                <p className='px-4 py-2 w-1/4 bg-slate-100 font-semibold text-base border rounded-tl-md'>{tableName}</p>
                <div className='px-4 py-2 w-3/4 bg-slate-100 font-semibold text-base border rounded-tr-md flex justify-between items-center'>
                    <p>Mapping</p>
                    {
                        editSection !== tableName ?
                            <Button onClick={() => seteditSection(tableName)} className='p-0 m-0 h-0' type='link'>Edit</Button>
                            :
                            <Button onClick={handleSave} className='p-0 m-0 h-0' type='link'>Save</Button>
                    }
                </div>
            </header>

            {
                tableData.map((data) => (
                    <div className='flex w-full' key={data}>
                        <p className='px-4 py-2 w-1/4 border bg-white font-semibold'>{data}</p>

                        {isEditable(data) ? (
                            <input
                                type="text"
                                className='px-4 py-2 w-3/4 border bg-gray-100'
                                value={formData[data] !== undefined ? formData[data] : uiMappingData[0][data]}  // Handle empty strings as valid input
                                onChange={(e) => handleInputChange(data, e.target.value)}  // Update formData on change
                            />
                        ) : (
                            <p className='px-4 py-2 w-3/4 border'>{formData[data] !== undefined ? formData[data] : uiMappingData[0][data]}</p>
                        )}
                    </div>
                ))
            }
        </div>
    );
}

export default UITable;
