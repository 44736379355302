import React from 'react'
import { useBase } from '../../../Layout/baseContext/BaseContext'
import UITable from './UITable';

const UIMapping = () => {
  const { uiMappingData } = useBase();
  const headers = Object.keys(uiMappingData[0])
  
  // Filter for item-related columns (starts with 'IT' or 'IA')
  const items = headers.filter(header => header.toString().startsWith('I') )    
  
  // Filter for location-related columns (starts with 'LO' or 'LA')
  const locations = headers.filter(header => header.toString().startsWith('L')) 
  
  // Filter for customer-related columns (starts with 'CU' or 'CA')
  const customers = headers.filter(header => header.toString().startsWith('C')) 
  
  return (
    <div className='flex flex-col h-full w-full text-xs text-bodyTextBlue  overflow-y-scroll'>
      <div className='grid grid-cols-3 gap-4 w-full'>
        <UITable tableName={"Items"} tableData={items} />
        <UITable tableName={"Locations"} tableData={locations} />
        <UITable tableName={"Customers"} tableData={customers} />
      </div>
    </div>
  )
}

export default UIMapping