import React from 'react'
import postDefaultLogo from "../../../components/assets/img/postDefaultLogo.png"
import { MdOutlinePeopleAlt } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import ActivityPost from './ActivityPost';
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import { Button } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import PostGraph from './PostGraph';

const SelectedPost = () => {
    // const { id } = useParams();
    // 
    // const postId = parseInt(id);

    const { posts, loading, selectedPost } = usePost();
    
    // const post = posts.find(post => post.id === postId);
    // 

    if (loading) {
        return <div className='h-full flex justify-center items-center'><TailSpin
            visible={true}
            height="40"
            width="40"
            color="#2B3674"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
        /></div>;
    }

    if (!<selectedPost />) {
        return <div>Post not found.</div>;
    }
    return (
        <>
            {
                loading ?
                    (<div className='h-full flex justify-center items-center'><TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color="#2B3674"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /></div>)
                    :
                    (<>
                        {selectedPost && (
                            <div className='bg-white text-bodyTextBlue rounded-2xl p-4 shadow-sm flex flex-col h-full '>

                                <div className='flex justify-between items-center mb-3'>
                                    <div className='flex gap-4 items-center'>
                                        <div>
                                            <img src={postDefaultLogo} width={40} alt="" />
                                        </div>
                                        <div className='flex flex-col '>
                                            <h2 className='font-bold'>{`${selectedPost.id}-${selectedPost.ITEM_VALUE}-${selectedPost.LOCATION_VALUE}-${selectedPost.CUSTOMER_VALUE}`}</h2>
                                            <p className='text-slate-400 text-sm'>{selectedPost.BY_USER}</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-1'>
                                        <div>
                                            {/* <MdOutlinePeopleAlt/> */}
                                            <Button className='border-0 p-0 text-base flex items-center justify-center' shape="circle" icon={<MdOutlinePeopleAlt />} />

                                        </div>
                                        <div>
                                            {/* <FiSettings/> */}
                                            <Button className='border-0 p-0 text-base flex items-center justify-center' shape="circle" icon={<FiSettings />} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className='h-full overflow-y-scroll overflow-x-hidden scroll px-2'>
                                    <ActivityPost post={selectedPost} />
                                    {/* <PostGraph/> */}
                                </div>
                            </div>
                        )}
                    </>)
            }
        </>

    )
}

export default SelectedPost