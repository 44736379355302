import { Button } from 'antd'
import React from 'react'
import { FiPlusCircle } from "react-icons/fi";
import defaultActivity from '../../../components/assets/img/defaultActivity.png'


const TeamAndUpdates = () => {
  return (
    <div className='w-full h-full py-4'>
      <div className='flex flex-row justify-between px-4'>
        <div>
          <h3 className='font-semibold text-sm'>Teams and Updates</h3>
        </div>
        <div className='flex flex-row gap-3'>
          <div className='text-blue-500 cursor-pointer gap-1 flex flex-row items-center'>
            <span><FiPlusCircle /></span>
            <span>Invite new member</span>
          </div>
          <div>
            <Button className='bg-blue-50 rounded-full hover:!bg-indigo-50' type='link'>See all</Button>
          </div>
        </div>
      </div>
      <div className='mt-2 flex flex-row'>
        <div className='h-full overflow-y-scroll border-r w-1/4'>
          <div className='text-slate-400'>
            <div className='flex flex-row px-4 hover:bg-slate-50 py-2 cursor-pointer'>
              <div className='rounded-full'>
                <img className='rounded-full' width={30} src={defaultActivity} alt="" />
              </div>
              <div className='flex flex-col ml-2'>
                <div className='font-semibold text-slate-500 '>Michael</div>
                <div>16 tasks in progress</div>
              </div>
            </div>
          </div>
          <div className='text-slate-400'>
            <div className='flex flex-row px-4 hover:bg-slate-50 py-2 cursor-pointer'>
              <div className='rounded-full'>
                <img className='rounded-full' width={30} src={defaultActivity} alt="" />
              </div>
              <div className='flex flex-col ml-2'>
                <div className='font-semibold text-slate-500 '>Joshua</div>
                <div>12 tasks in progress</div>
              </div>
            </div>
          </div>
          <div className='text-slate-400'>
            <div className='flex flex-row px-4 hover:bg-slate-50 py-2 cursor-pointer'>
              <div className='rounded-full'>
                <img className='rounded-full' width={30} src={defaultActivity} alt="" />
              </div>
              <div className='flex flex-col ml-2'>
                <div className='font-semibold text-slate-500 '>Mathew</div>
                <div>14 tasks in progress</div>
              </div>
            </div>
          </div>
          <div className='text-slate-400'>
            <div className='flex flex-row px-4 hover:bg-slate-50 py-2 cursor-pointer'>
              <div className='rounded-full'>
                <img className='rounded-full' width={30} src={defaultActivity} alt="" />
              </div>
              <div className='flex flex-col ml-2'>
                <div className='font-semibold text-slate-500 '>Ryan</div>
                <div>9 tasks in progress</div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-3/4 px-4'>
          <div>
            <div className='text-slate-400 flex flex-row items-center justify-between text-sm'>
              <div>Tasks and Updates by <span className='text-blue-500'>Naomi watts</span></div>
              <div className='bg-slate-50 py-1 px-2 rounded-lg'>Today</div>
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-row justify-between border-b py-2'>
                <div>
                  <div className='font-semibold'>Set up a meeting with "John" to discuss the adjustments on shipment "ARCM"</div>
                  <div className='text-slate-400'>1 hour ago</div>
                </div>
                <div>
                  <Button className='text-xs' type='link'>Request Invite</Button>
                </div>
              </div>
              <div className='flex flex-row justify-between border-b py-2'>
                <div>
                  <div className='font-semibold'>Made Adjustment for shipping ARCM</div>
                  <div className='text-slate-400'>3 hour ago</div>
                </div>
                <div>
                  <Button className='text-xs' type='link'>View Thread</Button>
                </div>
              </div>
              <div className='flex flex-row justify-between border-b py-2'>
                <div>
                  <div className='font-semibold'>Set up a meeting with "Josh" and "Michael" to discuss the adjustments on shipment "ARCM"</div>
                  <div className='text-slate-400'>4 hours ago</div>
                </div>
                <div>
                  <Button className='text-xs' type='link'>View details</Button>
                </div>
              </div>
              <div className='flex flex-row justify-between border-b py-2'>
                <div>
                  <div className='font-semibold'>Set up a meeting with "Chris" to discuss the adjustments on shipment "ARCM"</div>
                  <div className='text-slate-400'>5 hours ago</div>
                </div>
                <div>
                  <Button className='text-xs' type='link'>Request Invite</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamAndUpdates