import { Button } from 'antd';
import React from 'react'
import { IoAddOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";

import { useBase } from '../baseContext/BaseContext';


const ProfilePopUp = () => {
    const {favs,handleShowDefaultView,setNewActiveFav, defaultView} = useBase();
    return (
        <div className="flex flex-col gap-2 absolute top-10 right-0 w-56 bg-white box-shadow rounded-lg p-2 text-bodyTextBlue">
            <div className='rounded-md bg-slate-100'>
                <div className='flex justify-between px-3 items-center border-b border-b-slate-300 py-1'>
                    <h1 className='text-base font-semibold'>Favourites</h1>
                    <Button className='p-0 h-0' type='link'>
                        <span className='!p-0 !m-0'><IoAddOutline /></span>
                        <span>Add</span>
                    </Button>
                </div>
                <ul className='text-sm p-1'>
                    {
                        favs.length === 0 ?
                        <div className='flex justify-center items-center py-5'>
                            <p className='text-slate-400'>No Favourites</p>
                        </div>
                    
                    :
                        <>
                        
                            <li onClick={(() => handleShowDefaultView())} className='flex px-2 mb-1 rounded-md justify-between items-center py-1 capitalize cursor-pointer text-slate-500 hover:bg-white bg-slate-200'>
                                <p>Default View</p>
                                <p className='text-green-500'>{defaultView ? <FaCheck/> : ""}</p>
                            </li>
                        {

                            favs.map((item, index) => (
                                <li onClick={() => setNewActiveFav(item.id)} key={index} className='flex px-2 rounded-md justify-between items-center py-1 capitalize cursor-pointer text-slate-500 hover:bg-white'>
                                <p>{item.NAME}</p>
                                <p className='text-green-500'>{item.ISACTIVE && !defaultView ? <FaCheck/> : ""}</p>
                            </li>
                        ))
                    }
                        </>
                    }
                  
                </ul>
            </div>
            {/* <div className='rounded-md bg-slate-100'>
                <div className='flex justify-between px-3 items-center border-b border-b-slate-300 py-1'>
                    <h1 className='text-base font-semibold'>Favourites</h1>
                    <Button className='p-0 h-0' type='link'>
                        <span className='!p-0 !m-0'><IoAddOutline /></span>
                        <span>Add</span>
                    </Button>
                </div>
                <ul className='text-sm p-1'>
                    {
                        favs.length === 0 ?
                        <div className='flex justify-center items-center py-5'>
                            <p className='text-slate-400'>No Favourites</p>
                        </div>
                    
                    :
                        favs.map((item, index) => (
                            <li key={index} className='flex px-2 rounded-md justify-between items-center py-1 capitalize cursor-pointer text-slate-500 hover:bg-white'>
                                <p>{item.NAME}</p>
                                <p className='text-green-500'>{item.ISACTIVE ? <FaCheck/> : ""}</p>
                            </li>
                        ))
                    }
                  
                </ul>
            </div> */}
        </div>
    )
}

export default ProfilePopUp