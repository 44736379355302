import React from 'react';
import { Line } from 'react-chartjs-2';
import { useAlert } from '../../Layout/baseContext/alertsContext/AlertsContext';
import { TailSpin } from 'react-loader-spinner';

const LineChart = () => {
    const { alertsGraphData, highlightDate,alertGraphLoading } = useAlert();

    // Extract dates and sqty_lfu values directly from the alertsGraphData array
    const dates = alertsGraphData && alertsGraphData.map(entry => entry.SDATE);
    const sqtyValues = alertsGraphData && alertsGraphData.map(entry => entry.sqty_lfu);

    // Customize point styles based on highlightDate
    const pointBackgroundColors = dates && dates.map(date => 
        date === highlightDate ? '#ef4444' : '#ffdb5c' // Highlighted date is red, others are default color
    );

    const pointBorderColors = dates && dates.map(date => 
        date === highlightDate ? '#ef4444' : '#ffdb5c' // Highlighted date border is red, others are default color
    );

    const pointRadii = dates && dates.map(date => 
        date === highlightDate ? 5 : 3 // Set point radius to 5 for the highlighted date, otherwise 3
    );

    const chartData = {
        labels: dates,
        datasets: [{
            label: '', // Remove the label
            data: sqtyValues,
            borderColor: '#ffdb5c',
            backgroundColor: '#ffdb5c',
            borderWidth: 2,
            pointRadius: pointRadii, // Apply the conditional point radii
            pointHoverRadius: 5,
            pointBackgroundColor: pointBackgroundColors, // Apply the conditional background colors
            pointBorderColor: pointBorderColors // Apply the conditional border colors
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: false,
                    text: 'SQTY'
                }
            },
            x: {
                grid: {
                    display: false
                },
                title: {
                    display: false,
                    text: 'Date'
                }
            }
        },
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
            tooltip: {
                mode: 'index',
                intersect: false
            }
        }
    };

    return (
        <div className='bg-white p-3 rounded-xl border shadow-sm w-1/2'>
            {
                alertGraphLoading ? 
                <div className='h-full flex justify-center items-center'>
                <TailSpin
                    visible={true}
                    height="28"
                    width="28"
                    color="#2B3674"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
            :

            <Line data={chartData} options={options} />
            }
        </div>
    );
};

export default LineChart;
