import React from 'react';

const CurvedRadio = ({ checked, onChange, label }) => {
  return (
    <label className="inline-flex w-full h-full mt-2 items-center cursor-pointer">
      <input
        type="radio"
        className="hidden"
        checked={checked}
        onChange={onChange}
      />
      <div className="w-4 h-4 rounded-[3px] border border-gray-300 flex items-center justify-center transition duration-300 ease-in-out mx-auto my-auto">
        {checked && <div className="w-4 h-4 rounded-[3px] bg-blue-400"></div>}
      </div>
      <span className="text-gray-700">{label}</span>
    </label>
  );
};

export default CurvedRadio;
