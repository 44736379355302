import React from 'react'
import Dashboard from './components/Dashboard'
import { useDashboard } from '../Layout/baseContext/dashboardContext/DashboardContext'
import SeeAll from './components/SeeAll';

const DashboardContainer = () => {
  const { showSeeAll } = useDashboard();
  return (
    <>
      {
        showSeeAll !== "" &&
        <SeeAll />

      }
      <Dashboard />
    </>
  )
}

export default DashboardContainer