import React, { useState } from 'react'
import ParamCards from './ParamCards'
import { useBase } from '../../../Layout/baseContext/BaseContext'
import ParamTable from './ParamTable';

const ParamsTab = () => {
  const { params } = useBase();
  const uniqueLabels = [...new Set(params.map(param => param.LABEL))]
  const [selectedLabel, setSelectedLabel] = useState(null);

  return (
    <div className='w-full h-full text-bodyTextBlue text-xs '>
      {
        selectedLabel === null ?
          <div className='grid grid-cols-4 w-full gap-4'>
            {

              uniqueLabels && uniqueLabels.map((label) => (
                <ParamCards label={label} setSelectedLabel={setSelectedLabel} />
              ))
            }
          </div>
          :
          <ParamTable selectedLabel={selectedLabel} setSelectedLabel={setSelectedLabel} />
      }

    </div>
  )
}

export default ParamsTab