import { Button, InputNumber, Select } from 'antd';
import React from 'react'
import { IoCloseSharp } from "react-icons/io5";
import ProposalTable from './ProposalTable';

const DetailedProposal = ({
    setSelectedLocation,
    setSelectedCustomer,
    fields,
    setSelectedItem,
    setFields,
    uiMappingData,
    additionalFields,
    trasnposedData,
    midIndex,
    tdRef,
    tdateVar,
    startDate,
    endDate,
    setDetailedProposal,
    data,
    details
}) => {
    return (
        <div className='fixed h-full w-full top-0 left-0 flex justify-center items-center z-50 '>
            <div className="bg-slate-100 w-[80vw] h-[80vh] overflow-y-scroll overflow-x-hidden border-2 border-slate-300 rounded-lg shadow-[rgba(203,213,225)_0px_0px_30px]">
                <div className='flex rounded-t-lg bg-white justify-between p-2 '>
                    <h3 className='text-sm font-semibold text-bodyTextBlue pl-1'>Viewing Insights for Demand Row</h3>
                    <div onClick={() => setDetailedProposal(false)} className='hover:text-red-500 cursor-pointer flex items-center h-full pt-[2px] text-base'>
                        <IoCloseSharp />
                    </div>
                </div>
                <div className='p-4 w-full'>
                    <div className='overflow-x-scroll  overflow-y-hidden'>
                        <table>
                            <thead>
                                <tr className='font-semibold'>
                                    {additionalFields.length === 0 &&
                                        <>
                                            <td className='sticky bg-slate-100 shadow-col  left-[0px] z-40  rounded-tl-sm text-left  text-xs py-1 border-1 border-gray-200 min-w-[180px] max-w-[180px]'>
                                                <select
                                                    className='w-[150px] h-full bg-slate-100'
                                                    onChange={(e) => {
                                                        setFields({ ...fields, "item": e.target.value });
                                                        setSelectedItem(e.target.value)
                                                    }}
                                                    defaultValue={fields["item"]}>
                                                    <>
                                                        {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                            if (item.toString().substring(0, 1) === "I" && uiMappingData[0][item] !== null) {
                                                                return (
                                                                    <option value={item}>{uiMappingData[0][item]}</option>
                                                                )
                                                            }
                                                        })}
                                                    </>
                                                </select>
                                            </td>
                                            <td className='sticky min-w-[180px] max-w-[180px] shadow-col bg-slate-100  left-[180px] z-40 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200 '>
                                                <select
                                                    className=' w-[150px] h-full bg-slate-100'
                                                    onChange={(e) => {
                                                        setFields({ ...fields, "location": e.target.value })
                                                        setSelectedLocation(e.target.value)
                                                    }}
                                                    defaultValue={fields["location"]}>
                                                    <>
                                                        {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                            if (item.toString().substring(0, 1) === "L" && uiMappingData[0][item] !== null) {
                                                                return (
                                                                    <option value={item}>{uiMappingData[0][item]}</option>
                                                                )
                                                            }
                                                        })}
                                                    </>
                                                </select>
                                            </td>
                                            <td className='sticky min-w-[180px] max-w-[180px] shadow-col  bg-slate-100 left-[360px] z-40 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200'>
                                                <select
                                                    className=' w-[150px] h-full bg-slate-100'
                                                    onChange={(e) => {
                                                        setFields({ ...fields, "category": e.target.value })
                                                        setSelectedCustomer(e.target.value)

                                                    }}
                                                    defaultValue={fields["category"]}>
                                                    <>
                                                        {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                            if (item.toString().substring(0, 1) === "C" && uiMappingData[0][item] !== null) {
                                                                return (
                                                                    <option value={item}>{uiMappingData[0][item]}</option>
                                                                )
                                                            }
                                                        })}
                                                    </>
                                                </select>
                                            </td>
                                        </>
                                    }
                                    {
                                        additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                            const sp = (((index) * 180))
                                            const leftValue = `${sp}px`;
                                            if (index === 0) {

                                                return (
                                                    <td className={`sticky min-w-[180px] max-w-[180px]  shadow-col bg-slate-100  px-3 text-xs py-1 border-1 border-gray-200 z-40`}
                                                        style={{ left: "0px" }}
                                                        key={index}
                                                    >
                                                        {uiMappingData[0][item]}
                                                    </td>
                                                )
                                            }
                                            else {
                                                return (
                                                    <td className={`sticky min-w-[180px] max-w-[180px]  shadow-col bg-slate-100 left-[${sp}px] px-3 text-xs py-1 border-1 border-gray-200 z-40`}
                                                        key={index}
                                                        style={{ left: leftValue }}

                                                    >
                                                        {uiMappingData[0][item]}
                                                    </td>
                                                )
                                            }

                                        })}
                                    {trasnposedData.size !== 0 && Object.keys(trasnposedData.get([...trasnposedData.keys()][0])).map((item, index) => {
                                        if (item === tdateVar && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                            midIndex = index
                                            return (<React.Fragment key={index}>
                                                <td colSpan={2} className='rounded-tl-sm bg-slate-100  shadow-col  text-center px-3 text-xs py-1 border-1 border-gray-200'>{item}</td>
                                            </React.Fragment>)
                                        }
                                        else {
                                            if (midIndex !== 0 && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate) && index === midIndex + 6) {
                                                return (<React.Fragment key={index}>
                                                    <td className='rounded-tl-sm shadow-col bg-slate-100 text-center px-3 text-xs py-1 border-1 border-gray-200'>{item}</td>
                                                </React.Fragment>)
                                            }
                                            else if (new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                return (<React.Fragment key={index}>
                                                    <td className='rounded-tl-sm text-center bg-slate-100 shadow-col px-3 text-xs py-1 border-1 border-gray-200'>{item}</td>
                                                </React.Fragment>)
                                            }
                                        }
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        additionalFields.length === 0 &&
                                        <>
                                            <td className='sticky bg-white shadow-col px-4 left-[0px]  min-w-[180px] max-w-[180px] text-left  py-2 text-xs border border-gray-200'>{details[0] !== null ? details[0] : "Not Available"}</td>
                                            <td className='sticky bg-white shadow-col px-4 left-[180px] min-w-[180px] max-w-[180px] text-left  py-2 text-xs border border-gray-200'>{details[1] !== null ? details[1] : "Not Available"}</td>
                                            <td className='sticky bg-white shadow-col px-4 left-[360px] min-w-[180px] max-w-[180px] text-left  py-2 text-xs border border-gray-200'>{details[2] !== null ? details[2] : "Not Available"}</td>
                                        </>
                                    }
                                    {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                        const sp = (90 + ((index) * 180))
                                        
                                        const leftValue = `${sp}px`;
                                        if (index === 0) {
                                            return (
                                                <td className={`sticky text-left px-3 py-2 min-w-[180px] max-w-[180px]
                                                                        left-[90px] shadow-col
                                                                        text-xs bg-white border-1 border-gray-200`} key={index}>{details[3 + index] !== null ? details[3 + index] : "Not Available"}</td>
                                            )
                                        }
                                        else {
                                            return (
                                                <td style={{ left: leftValue }} className={`sticky text-left px-3 py-2 min-w-[180px] max-w-[180px]
                                                                         shadow-col   text-xs bg-white border-1 border-gray-200`} key={index}>{details[3 + index] !== null ? details[3 + index] : "Not Available"}</td>
                                            )
                                        }
                                    })}
                                    {trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {
                                        if (item["isSplit"] === true) {
                                            return (
                                                <>
                                                    <td className='bg-gray-50'>
                                                        <p className='p-2 h-full w-full bg-gray-50'>{Intl.NumberFormat('en-US', {
                                                            notation: "compact",
                                                            maximumFractionDigits: 1
                                                        }).format(item["SQTY"] !== null ? item["SQTY"] : 0)}</p>
                                                    </td>
                                                    <td className={`text-left text-xs px-0 -py-1 bg-gray-50 text-[#4285F4]  `}>


                                                        <p
                                                            className={`p-2 h-full w-full
                                                                                    ${item["FQTY"] !== null ?
                                                                    item["FQTY"] === item["FQTY_USER"] ?
                                                                        "" : "text-white bg-[#ed7d31]"
                                                                    :
                                                                    ""}
                                                                                `}                                                                                      >{Intl.NumberFormat('en-US', {
                                                                        notation: "compact",
                                                                        maximumFractionDigits: 1
                                                                    }).format(item["FQTY"] !== null ?
                                                                        (item["FQTY"] === item["FQTY_USER"] ?
                                                                            (item["FQTY"]) : (item["FQTY_USER"]))
                                                                        :
                                                                        (0))}
                                                        </p>

                                                    </td>
                                                </>
                                            );
                                        }
                                        else if (item["SQTY"] !== null && item["SQTY"] !== 0 && index < midIndex) {
                                            return (
                                                <td className={`text-left text-xs px-0 -py-1 bg-gray-50 text-[#646666]`}>


                                                    <p className='p-2 h-full w-full'>{Intl.NumberFormat('en-US', {
                                                        notation: "compact",
                                                        maximumFractionDigits: 1
                                                    }).format(item["SQTY"] !== null ? item["SQTY"] : 0)}</p>

                                                </td>
                                            );
                                        } else if (item["FQTY"] !== null && item["FQTY"] !== 0) {
                                            return (
                                                <td className={`text-left text-xs px-0 -py-1 bg-gray-50 text-[#4285F4]  `}>

                                                    <p
                                                        className={`p-2 h-full w-full
                                                                                ${item["FQTY"] !== null ?
                                                                item["FQTY"] === item["FQTY_USER"] ?
                                                                    "" : "text-white bg-[#ed7d31]"
                                                                :
                                                                ""}
                                                                            `}                                                                                  >{Intl.NumberFormat('en-US', {
                                                                    notation: "compact",
                                                                    maximumFractionDigits: 1
                                                                }).format(item["FQTY"] !== null ?
                                                                    (item["FQTY"] === item["FQTY_USER"] ?
                                                                        (item["FQTY"]) : (item["FQTY_USER"]))
                                                                    :
                                                                    (0))}</p>

                                                </td>
                                            );
                                        }
                                        else if (item["SQTY"] !== null && item["SQTY"] === 0 && index < midIndex) {
                                            return (
                                                <td className='bg-gray-50'>
                                                    <p className='p-1 h-full w-full bg-gray-50'>{Intl.NumberFormat('en-US', {
                                                        notation: "compact",
                                                        maximumFractionDigits: 1
                                                    }).format(item["SQTY"] !== null ? item["SQTY"] : 0)}</p>
                                                </td>
                                            );
                                        }
                                        else if (item["FQTY"] !== null && item["FQTY"] === 0) {
                                            return (
                                                <td className={`text-left text-xs px-0 -py-1 bg-gray-50 text-[#4285F4]  `}>

                                                    <p
                                                        className={`p-2 h-full w-full
                                                                                ${item["FQTY"] !== null ?
                                                                item["FQTY"] === item["FQTY_USER"] ?
                                                                    "" : "text-white bg-[#ed7d31]"
                                                                :
                                                                ""}
                                                                            `}                                                                                  >{Intl.NumberFormat('en-US', {
                                                                    notation: "compact",
                                                                    maximumFractionDigits: 1
                                                                }).format(item["FQTY"] !== null ?
                                                                    (item["FQTY"] === item["FQTY_USER"] ?
                                                                        (item["FQTY"]) : (item["FQTY_USER"]))
                                                                    :
                                                                    (0))}</p>

                                                </td>
                                            );
                                        }
                                        else if (item["SQTY"] === null && item["FQTY"] === null && index > midIndex) {
                                            return (
                                                <td className={`text-left text-xs px-0 -py-1 bg-gray-50 text-[#4285F4]  `}>

                                                    <p
                                                        className={`p-2 h-full w-full
                                                                                ${item["FQTY"] !== null ?
                                                                item["FQTY"] === item["FQTY_USER"] ?
                                                                    "" : "text-white bg-[#ed7d31]"
                                                                :
                                                                ""}
                                                                            `}                                                                                >{Intl.NumberFormat('en-US', {
                                                                    notation: "compact",
                                                                    maximumFractionDigits: 1
                                                                }).format(item["FQTY"] !== null ?
                                                                    (item["FQTY"] === item["FQTY_USER"] ?
                                                                        (item["FQTY"]) : (item["FQTY_USER"]))
                                                                    :
                                                                    (0))}</p>

                                                </td>
                                            );
                                        }

                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-10 flex flex-row items-center gap-4 font-semibold text-slate-400'>
                        <div className='text-blue-500 border-b-2 border-blue-500'>List of Proposals</div>
                        <div className='cursor-pointer'>Events</div>
                        <div className='cursor-pointer'>News</div>
                    </div>
                    <div className='mt-4 text-bodyTextBlue w-full font-semibold flex flex-row justify-between items-center'>
                        <div >Showing 5 Proposals raised for this entity</div>
                        <div className='flex flex-row items-center gap-2'>
                            <div>
                                for 2023-01-01, if the Probability of success
                            </div>
                            <div>
                                <Select
                                    defaultValue="greater"
                                    style={{ width: 120 }}
                                    // onChange={handleChange}
                                    options={[
                                        { value: 'greater', label: 'greater than' },
                                        { value: 'lower', label: 'lower than' },

                                    ]}
                                />
                            </div>
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ width: 50 }}
                                defaultValue={0}
                            />
                            <span>%</span>
                            <div>
                                And
                            </div>
                            <div>
                                <Select
                                    defaultValue="lower"
                                    style={{ width: 120 }}
                                    // onChange={handleChange}
                                    options={[
                                        { value: 'greater', label: 'greater than' },
                                        { value: 'lower', label: 'lower than' },

                                    ]}
                                />
                            </div>
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ width: 50 }}
                                defaultValue={100}
                            />
                            <span>%</span>
                            <div className='pl-4'>
                                <Button type='primary'>Adjust Demand Values</Button>
                            </div>
                        </div>

                    </div>

                    <div className='w-full overflow-scroll  mt-4'>
                        <ProposalTable
                             trasnposedData={trasnposedData}
                             tdateVar={tdateVar}
                             startDate={startDate}
                             endDate={endDate}
                             midIndex={midIndex}
                             tdRef={tdRef}
                             setDetailedProposal={setDetailedProposal}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DetailedProposal