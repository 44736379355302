import React from 'react'
import defaultActivity from "../../../components/assets/img/defaultActivity.png"
import { FaAngleRight } from "react-icons/fa6";
import ModificationForm from './ModificationForm';
import { Button } from 'antd';
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import { useBase } from '../../Layout/baseContext/BaseContext';


const ActivityPost = () => {
    const { selectedPost, setDiscussion} = usePost();

    const {uiMappingData} = useBase()

    const dateOnly = selectedPost && selectedPost.UPDATED_AT.split('T')[0];

    return (
      <div className='flex flex-col mt-2'>
            <h2 className='font-bold pl-2'>Activity Posts</h2>
            <div  className='flex flex-col h-full p-3 mt-2 shadow-[rgba(203,213,225,0.5)_0px_0px_10px] rounded-xl'>
                <div className=' flex gap-2 border-b border-slate-100 pb-2'>
                    <div className='flex-shrink-0'>
                        <img src={defaultActivity} width={30} alt="" />
                    </div>
                    <div className='text-xs'>
                        <p className='leading-6 -indent-2 pl-2'>
                            <span className='bg-violet-100 text-violet-700 font-semibold rounded-full px-3 py-1 mr-2'>{selectedPost.BY_ROLE}</span>
                            <span className='font-semibold '>{`User ${selectedPost.BY_USER} adjusted ${Intl.NumberFormat('en-US', {notation: "compact", maximumFractionDigits: 2}).format(selectedPost.PREVIOUS_VALUE)} to ${Intl.NumberFormat('en-US', {notation: "compact", maximumFractionDigits: 2}).format(selectedPost.NEW_VALUE)} at ${uiMappingData[0][selectedPost.ITEM_LEVEL]}-${uiMappingData[0][selectedPost.LOCATION_LEVEL]}-${uiMappingData[0][selectedPost.CUSTOMER_LEVEL]} with values ${selectedPost.ITEM_VALUE}-${selectedPost.LOCATION_VALUE}-${selectedPost.CUSTOMER_VALUE} at ${selectedPost.TIME_LEVEL} for ${selectedPost.TIME_VALUE} with an initial comment ${selectedPost.INITIAL_COMMENT}`}</span>
                        </p>
                    </div>
                  
                </div>
                
                <div className='flex justify-between mt-2'>
                    <div className='flex gap-x-4 pl-2 flex-wrap'>
                        <div className='flex text-xs items-center'>
                            <p className='text-inactiveText'>Status:</p>
                            <Button type='link' className='flex ml-2 items-center text-xs p-0' onClick={() => setDiscussion(true)}>
                                <p className='underline mr-1 font-semibold capitalize' >{selectedPost.STATUS} Discussion</p>
                                <span className='pt-1'>
                                    <FaAngleRight />
                                </span>
                            </Button>
                        </div>
                        <div className='flex text-xs items-center '>
                            <p className='text-inactiveText'>Last Edits Made:</p>
                            <Button type='link' className='flex ml-2 items-center text-xs p-0'>
                                <p className='underline mr-1 font-semibold'>{dateOnly}</p>
                                <span className='pt-1'>
                                    <FaAngleRight />
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div className='flex gap-2 text-xs flex-wrap justify-end'>
                        <button className='bg-slate-100 text-green-500 font-semibold rounded-full px-3 py-1 hover:bg-slate-200 w-40'>Approve changes</button>
                        <button className='bg-slate-100 text-red-500 font-semibold rounded-full px-3 py-1 hover:bg-slate-200 w-40'>Raise changes</button>
                    </div>
                </div>
                <ModificationForm/>
            </div>
        </div>
       
    )
}

export default ActivityPost