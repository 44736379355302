import { Button } from 'antd'
import React from 'react'
import { IoWarningOutline } from "react-icons/io5";
import defaultActivity from '../../../components/assets/img/defaultActivity.png'

const PendingActions = () => {
  return (
    <div className='flex flex-col h-full p-4'>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row items-center'>
          <h3 className='font-semibold text-sm'>Priorities</h3>
          <div className='text-orange-600 flex flex-row gap-1 items-center ml-2'>
            <div><IoWarningOutline /></div>
            <div>1 Critical Task </div>
          </div>
        </div>
        <div>
          <Button className=' bg-blue-50 rounded-full hover:!bg-indigo-50 text-xs' type='link'>See all</Button>
        </div>
      </div>
      <div className='text-slate-400 mt-2 flex flex-col gap-3'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row'>
            <div className='rounded-full min-w-7 max-w-7'>
              <img className='rounded-full' src={defaultActivity} alt="" />
            </div>
            <div className='ml-2'>
              <div>
                <span className='font-semibold text-slate-500 mr-1'>Tom</span>
                <span>raised a concern in the food service category </span>
              </div>
              <div className='mt-1'>
                <span className='font-semibold text-slate-500 mr-1'>1 hour ago</span>
                <span className='bg-orange-50 text-orange-500 px-2 py-[1px]  rounded-lg'>Critical</span>
              </div>
            </div>
          </div>
          <div>
            <Button className='text-xs font-semibold' type='link'>View</Button>
          </div>
        </div>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row'>
            <div className='rounded-full min-w-7 max-w-7'>
              <img className='rounded-full'  src={defaultActivity} alt="" />
            </div>
            <div className='ml-2'>
              <div>
                {/* <span className='font-semibold text-slate-500 mr-1'>Username</span> */}
                <span>Demand forecast for September needs to be submitted </span>
              </div>
              <div className='mt-1'>
                <span className='font-semibold text-slate-500 mr-1'>2 hours ago</span>
                {/* <span className='bg-orange-50 text-orange-500 px-2 py-[1px]  rounded-lg'>Critical</span> */}
              </div>
            </div>
          </div>
          <div>
            <Button className='text-xs font-semibold' type='link'>View</Button>
          </div>
        </div>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row'>
            <div className='rounded-full min-w-7 max-w-7'>
              <img className='rounded-full' src={defaultActivity} alt="" />
            </div>
            <div className='ml-2'>
              <div>
                {/* <span className='font-semibold text-slate-500 mr-1'>Username</span> */}
                <span>Review new product launch in France </span>
              </div>
              <div className='mt-1'>
                <span className='font-semibold text-slate-500 mr-1'>5 hours ago</span>
                {/* <span className='bg-orange-50 text-orange-500 px-2 py-[1px]  rounded-lg'>Critical</span> */}
              </div>
            </div>
          </div>
          <div>
            <Button className='text-xs font-semibold' type='link'>View</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingActions