import React from 'react'
import FlowDiagram from './components/FlowDiagram'
import {  ReactFlowProvider } from '@xyflow/react';

const diagramContainer = () => {
  return (
    <ReactFlowProvider>
      <FlowDiagram />

    </ReactFlowProvider>

  )
}

export default diagramContainer