import React from 'react';


const Templates = ({formattedData}) => {
    

 
    return (
        <div>
            
            {
                Object.keys(formattedData).map((row) => {
                    return(
                        <table key={row}>
                            <tbody>
                                <tr>
                                    {
                                        formattedData[row].map((col) => {
                                            return(
                                                <th key={col}>{col}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    )
                })
            }
        </div>
    )
}

export default Templates;
