import React, { createContext, useState, useContext, useEffect } from 'react';
import categories from "../categories.json";

const QuesContext = createContext();

export const QuesProvider = ({ children }) => {
    
    const [showQues, setShowQues] = useState(false)

    const getCategoryStatus = (category) => {
      const categoryResponses = responses[category] || {};
      const totalQuestions = categories.find(cat => cat.category === category).questions.length;
      const answeredQuestions = Object.keys(categoryResponses).length;
  
      if (answeredQuestions === 0) {
        return 'none'; // No questions answered
      } else if (answeredQuestions === totalQuestions) {
        return 'all'; // All questions answered
      } else {
        return 'some'; // Some questions answered
      }
    };

    const [responses, setResponses] = useState(() => {
      const savedResponses = localStorage.getItem('responses');
      return savedResponses ? JSON.parse(savedResponses) : {};
    });
  
    // Use useEffect to update responses from local storage
    useEffect(() => {
      const savedResponses = localStorage.getItem('responses');
      if (savedResponses) {
        setResponses(JSON.parse(savedResponses));
      }
    }, []);

  return (
    <QuesContext.Provider value={{showQues, setShowQues,getCategoryStatus,responses, setResponses}}>
      {children}
    </QuesContext.Provider>
  );
};

export const useQues = () => useContext(QuesContext);