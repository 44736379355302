
import Posts from './pages/Posts';
// import { PostProvider } from './context/PostContext';

const PostsContainer = () => {
    return (
        // <PostProvider>
            <Posts />
        // </PostProvider>
    )
}

export default PostsContainer