import { ExpandOutlined, PlusCircleOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import Overlay from '../checkboxOverlay';
import { TbTablePlus } from "react-icons/tb";
import { useBase } from '../Layout/baseContext/BaseContext';

const PerformanceTable = ({
    columnsShown,
    setColumnShown,
    selectedATTs,
    setSelectedATTs,
    calculateMetrics,
    performanceResult,
    intervalType,
    tempTableRef,
    errType,
    uiMappingData,
    setCurrentGraph,
    // getPerformanceData,
    performanceData,
    tdateVar,
    startDate,
    setStartDate,
    intervalList,
    perFields,
    setPerFields,
    additionalFields,
    performanceLoading,
    locationFilters,
    productFilters,
    customerFilters
}) => {
    const [trasnposedData, setTransposedData] = useState(new Map());
    const [checkBoxOverLay, setCheckBoxOverlay] = useState(false);

    const [endDate, setEndDate] = useState(null)
    const divRef = useRef(null);
    const [uptoDecimal, setUptoDecimal] = useState(2)

    const formatNumber = (number, decimalPlaces) => {
        return Number(number).toFixed(decimalPlaces);
    };



    const handleCheckboxChange = (data, isChecked) => {
        // Filter keys that start with 'IATT', 'CATT', or 'LATT'
        const filteredData = {};
        Object.keys(data).forEach(key => {
            if (key.startsWith('IATT') || key.startsWith('CATT') || key.startsWith('LATT')) {
                filteredData[key] = data[key];
            }
        });
    
        // Update the state
        setSelectedATTs(prevSelected => {
            if (isChecked) {
                // Add the new filtered data
                return [...prevSelected, filteredData];
            } else {
                // Filter out the data if unchecked
                return prevSelected.filter(item => 
                    !Object.keys(filteredData).every(key => item[key] === filteredData[key])
                );
            }
        });
    };
    


    


    function filterPerformanceResult() {
        return performanceResult.filter(entry => {
          return selectedATTs.some(att => {
            return Object.keys(att).every(key => {
              return entry[key] === att[key];
            });
          });
        });
      }
      
    const checkingRow = () => {
        

        const validIntervalTypes = ['TDATE', 'FDATE'];
        if (!validIntervalTypes.includes(intervalType)) {
            console.error('Invalid interval type');
            return;
        }



        // Filter the data based on the selected IATT, LATT, CATT
        


        const filteredData = filterPerformanceResult()
    
        // Group data by intervalType (TDATE or FDATE)
        const groupedByIntervalType = filteredData.reduce((acc, item) => {
            const dateKey = item[intervalType];
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(item);
            return acc;
        }, {});

        // Calculate based on errType
        const result = {};
        for (const [date, records] of Object.entries(groupedByIntervalType)) {
            const totalSqty = records.reduce((sum, record) => sum + record.sqty, 0);
            if (errType === 'ape') {
                const totalTae = records.reduce((sum, record) => sum + record.tae, 0);
                const totalTaeUser = records.reduce((sum, record) => sum + record.tae_user, 0);
                result[date] = {
                    ape: (totalTae * 100) / totalSqty,
                    ape_user: (totalTaeUser * 100) / totalSqty
                };
            } else if (errType === 'bias') {
                const totalFqtyFinal = records.reduce((sum, record) => sum + record.fqty_final, 0);
                const totalFqtyUser = records.reduce((sum, record) => sum + record.fqty_user, 0);
                result[date] = {
                    bias: ((totalFqtyFinal - totalSqty) * 100) / totalSqty,
                    bias_user: ((totalFqtyUser - totalSqty) * 100) / totalSqty
                };
            } else if (errType === 'mape') {
                const avgApe = records.reduce((sum, record) => sum + record.ape, 0) / records.length;
                const avgApeUser = records.reduce((sum, record) => sum + record.ape_user, 0) / records.length;
                result[date] = {
                    mape: avgApe * 100,
                    mape_user: avgApeUser * 100
                };
            } else {
                console.error('Invalid errType');
                return;
            }
        }
        setCurrentGraph(result)
        
    };

    useEffect(() => {
        // if(selectedATTs.length === 0){
        //     const displayGraph = calculateMetrics(performanceGraphResult, intervalType, errType);
        //     setCurrentGraph(displayGraph)
        // }
        if (selectedATTs.length !== 0) {
            checkingRow();
        }
    }, [selectedATTs, errType, intervalType])
    useEffect(() => {
        if (intervalList.length !== 0) {

            setEndDate(intervalList[intervalList.length - 1][perFields["interval"]])
            
        }
    }, [intervalList])

   
    return (
        <div ref={divRef} className={`w-full max-h-full overflow-y-scroll shadow-col  shadow-class`}>
            <table ref={tempTableRef} className='hidden w-full whitespace-nowrap text-xs border border-collapse transition-all ease-in-out duration-500 overflow-scroll'>
                <thead className='w-full sticky top-0 border-b shadow-class bg-white z-50 ' >
                    <tr className='font-bold  bg-slate-100'>
                        <td className='  bg-slate-100  text-xs py-1 '>
                            {uiMappingData[0][perFields["item"]]}
                        </td>
                        <td className='  bg-slate-100  text-xs py-1 '>
                            {uiMappingData[0][perFields["location"]]}
                        </td>
                        <td className='  bg-slate-100  text-xs py-1 '>
                            {uiMappingData[0][perFields["category"]]}
                        </td>
                        {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                            const space = (252 + ((index + 1) * 100))
                            return (
                                <td key={index}>{item}</td>
                            )
                        })}
                        <td className='uppercase pl-4'>
                            engine ape
                        </td>
                        <td className='uppercase pl-4'>
                            planner ape
                        </td>
                        <td className='uppercase pl-4'>
                            engine bias
                        </td>
                        <td className='uppercase pl-4'>
                            planner bias
                        </td>
                        <td className='uppercase pl-4'>
                            engine mape
                        </td>
                        <td className='uppercase pl-4'>
                            planner mape
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {performanceData && performanceData.size !== 0 && performanceData.map((data, rowIndex) => {
                        
                        const keys = Object.keys(data);
                        
                        return (
                            <>

                                <tr className='font-semibold transition-all ease-in-out duration-500' key={rowIndex}>

                                    <td className='p-2'>{data[keys[0]]}</td>
                                    <td className='p-2'>{data[keys[1]]}</td>
                                    <td className='p-2'>{data[keys[2]]}</td>
                                    {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                        const space = (252 + ((index + 1) * 100)) * 1;
                                        return (
                                            <td key={index}>{"add"}</td>
                                        )
                                    })}
                                    <td className='pl-4'>{data["ape"]} %</td>
                                    <td className='pl-4'>{data["ape_user"]} %</td>
                                    <td className='pl-4'>{data["bias"]} %</td>
                                    <td className='pl-4'>{data["bias_user"]} %</td>
                                    <td className='pl-4'>{data["mape"]} %</td>
                                    <td className='pl-4'>{data["mape_user"]} %</td>

                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
            <table className='w-full whitespace-nowrap text-xs border border-collapse transition-all ease-in-out duration-500 overflow-scroll'>
                <thead className='w-full sticky top-0 border-b shadow-sm shadow-class bg-white z-50 ' >
                    <tr className='font-bold  bg-slate-100'>
                        <td className=' text-center text-base text-blue-500  no-download-col' onClick={() => setCheckBoxOverlay(true)}><button className='w-full h-full' ><TbTablePlus /></button></td>
                        <td></td>
                        {additionalFields.length === 0 &&
                            <>
                                <td className='w-[180px]  bg-slate-100  text-xs py-1 '>
                                    <select
                                        className='w-[150px] h-full bg-slate-100'
                                        onChange={(e) => {
                                            setPerFields({ ...perFields, "item": e.target.value });
                                            // setSelectedItem(e.target.value)
                                        }}
                                        defaultValue={perFields["item"]}>
                                        <>
                                            {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                if (item.toString().substring(0, 1) === "I" && uiMappingData[0][item] !== null) {
                                                    return (
                                                        <option value={item}>{uiMappingData[0][item]}</option>
                                                    )
                                                }
                                            })}
                                        </>
                                    </select>
                                </td>
                                <td className='w-[180px]  bg-slate-100  text-xs py-1 '>
                                    <select
                                        className='w-[150px] h-full bg-slate-100'
                                        onChange={(e) => {
                                            setPerFields({ ...perFields, "location": e.target.value })
                                            // setSelectedLocation(e.target.value)
                                        }}
                                        defaultValue={perFields["location"]}>
                                        <>
                                            {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                if (item.toString().substring(0, 1) === "L" && uiMappingData[0][item] !== null) {
                                                    return (
                                                        <option value={item}>{uiMappingData[0][item]}</option>
                                                    )
                                                }
                                            })}
                                        </>
                                    </select>
                                </td>
                                <td className='w-[180px]  bg-slate-100  text-xs py-1 '>
                                    <select
                                        className='w-[150px] h-full bg-slate-100'
                                        onChange={(e) => {
                                            setPerFields({ ...perFields, "category": e.target.value })
                                            // setSelectedCustomer(e.target.value)

                                        }}
                                        defaultValue={perFields["category"]}>
                                        <>
                                            {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                if (item.toString().substring(0, 1) === "C" && uiMappingData[0][item] !== null) {
                                                    return (
                                                        <option value={item}>{uiMappingData[0][item]}</option>
                                                    )
                                                }
                                            })}
                                        </>
                                    </select>
                                </td>
                            </>
                        }
                        {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                            const space = (252 + ((index + 1) * 100))
                            return (
                                <td className='w-[180px]  bg-slate-100  text-xs py-1 ' key={index}>{uiMappingData[0][item]}</td>
                            )
                        })}
                        <td className='uppercase pl-4'>
                            engine ape
                        </td>
                        <td className='uppercase pl-4'>
                            planner ape
                        </td>
                        <td className='uppercase pl-4'>
                            engine bias
                        </td>
                        <td className='uppercase pl-4'>
                            planner bias
                        </td>
                        <td className='uppercase pl-4'>
                            engine mape
                        </td>
                        <td className='uppercase pl-4'>
                            planner mape
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {performanceData && performanceData.size !== 0 && performanceData.map((data, rowIndex) => {
                        
                        const keys = Object.keys(data);
                        
                        return (
                            <>

                                <tr className='font-semibold transition-all ease-in-out duration-500' key={rowIndex}>
                                    {rowIndex === 0 ? <td className='bg-white text-center h-full'><ExpandOutlined className='cursor-pointer pl-1' /></td> : <td className='bg-white' ></td>}
                                    <td className='bg-white'>
                                        <input type="checkbox" id="performance-checkbox"
                                            onChange={(e) => {
                                                
                                                handleCheckboxChange(data, e.target.checked);
                                            }}
                                        />
                                    </td>
                                    {additionalFields.length === 0 &&
                                        <>
                                            <td className='p-2 bg-white w-[180px]'>{data[keys[0]]}</td>
                                            <td className='p-2 bg-white w-[180px]'>{data[keys[1]]}</td>
                                            <td className='p-2 bg-white w-[180px]'>{data[keys[2]]}</td>
                                        </>
                                    }
                                    {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                        
                                        const space = (252 + ((index + 1) * 100)) * 1;
                                        return (
                                            <td className='p-2 bg-white w-[180px]' key={index}>{data[item]}</td>
                                        )
                                    })}
                                    <td className='pl-4'>{data["ape"]} %</td>
                                    <td className='pl-4'>{data["ape_user"]} %</td>
                                    <td className='pl-4'>{data["bias"]} %</td>
                                    <td className='pl-4'>{data["bias_user"]} %</td>
                                    <td className='pl-4'>{data["mape"]} %</td>
                                    <td className='pl-4'>{data["mape_user"]} %</td>

                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
            {checkBoxOverLay === true ?
                <Overlay
                    fields={columnsShown.length !== 0 ? columnsShown : [perFields["item"], perFields["location"], perFields["category"]]}
                    locationFilters={locationFilters}
                    productFilters={productFilters}
                    customerFilters={customerFilters}
                    uiMappingData={uiMappingData}
                    setFields={setColumnShown}
                    onClose={() => setCheckBoxOverlay(false)} /> : null
            }
        </div>
    )
}

export default PerformanceTable