import React, { useState } from 'react';
import { useQues } from '../context/QuesContext';
import QuesBox from './QuesBox';
import categories from "../categories.json";
import { LuClock } from "react-icons/lu";
import { FaRegCircleCheck } from "react-icons/fa6";

const Ques = () => {
  const { showQues, setShowQues,getCategoryStatus } = useQues();
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null); // State to manage selected category index
  

  const handleCategoryClick = (index) => {
    setSelectedCategoryIndex(index);
    setShowQues(true);
  };

 

  return (
    <div className='flex flex-col w-full justify-center items-center min-h-screen text-xs px-4 text-bodyTextBlue pb-3'>
      <div>
        <h1 className='uppercase text-4xl'>Help Us To Get You Know Better</h1>
      </div>
      <div className='mt-10 flex flex-row gap-4 flex-wrap w-[70vw] justify-center items-center'>
        {categories.map((category, index) => {
          const status = getCategoryStatus(category.category);
          return (
            <div key={index} className=''>
              <div
                onClick={() => handleCategoryClick(index)}
                className='relative text-bodyTextBlue-500 box-shadow shadow-sm hover:bg-blue-50 bg-white cursor-pointer w-72 h-28 hover:scale-105 transition-all duration-100 p-6 flex flex-row justify-center items-center text-center text-lg rounded-lg'
              >
                {status === 'all' && (
                  <div className='absolute top-2 left-2 text-xl text-green-500'>
                    <FaRegCircleCheck />
                  </div>
                )}
                {status === 'some' && (
                  <div className='absolute top-2 left-2 text-xl text-yellow-500'>
                    <LuClock />
                  </div>
                )}
                {/* {status === 'none' && (
                  <div className='absolute top-2 left-2 text-xl text-blue-200'>
                    <FaRegCircle />
                  </div>
                )} */}
                {category.category}
              </div>
              {showQues && selectedCategoryIndex === index && (
                <QuesBox questions={category.questions} category={category.category} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Ques;
