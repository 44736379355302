import { Button } from 'antd';
import React from 'react'
import { HiOutlineLightBulb } from "react-icons/hi";

const Insight = () => {
  return (
    <div className='w-ful h-full p-4 flex flex-col justify-between'>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center'>
          <div className='text-2xl mr-1'><HiOutlineLightBulb /></div>
          <div className='font-semibold text-base'>Relevant insights</div>
        </div>
        <div className='mt-2 text-sm'>
          For September, seasonal demand has decreased the last 3 years by 10% on average.
        </div>
      </div>
      <div className='flex justify-center'>
        <Button className='bg-blue-50 rounded-full hover:!bg-indigo-50' type='link'>See all</Button>
      </div>
    </div>
  )
}

export default Insight