import React from 'react';

const DynamicForm = ({ changed_quantity, changed_reason }) => {
  return (
    <div className="flex flex-col space-y-4">
      {changed_quantity.map((quantity, index) => (
        <div key={index} className="flex space-x-4">
          <div className='flex flex-col w-full space-y-2'>
            <label className='font-light text-base text-gray-500'>Changed Quantity*</label>
            <input
              type="text"
              className="border border-gray-200 rounded-sm px-4 w-full py-3"
              value={""}
              placeholder={quantity}
              readOnly
            />
          </div>
          <div className='flex flex-col w-full space-y-2'>
            <label className='font-light text-base text-gray-500'>Changed Reason*</label>
            <input
              type="text"
              className="border border-gray-200 rounded-sm px-4 w-full py-3"
              placeholder={changed_reason[index]}
              value={""}
              readOnly
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default DynamicForm;
