import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../../../../api';

const PortfolioContext = createContext();

export const PortfolioProvider = ({ children, locationFilters, productFilters, customerFilters }) => {

  const [data, setdata] = useState(null);
  const [products, setProducts] = useState(productFilters);
  const [customers, setCustomers] = useState(customerFilters);
  const [locations, setLocations] = useState(locationFilters);
  const [loading, setLoading] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState(null);
  const [selectedChip, setSelectedChip] = useState("products");
  const [disContinueForm, setDisContinueForm] = useState(false);
  const [oldTransitionForm, setOldTransitionForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState("new");

  const fetchData = async () => {
    try {
      setLoading(true);

      const productResponse = await api.get("/api/products/?fields=ITEM,SOURCE");
      const customerResponse = await api.get("/api/customers/?fields=CUSTOMER,SOURCE");
      const locationResponse = await api.get("/api/locations/?fields=LOCATION,SOURCE");
    
      // Axios response data is available in response.data
      const productData = productResponse.data;
      const customerData = customerResponse.data;
      const locationData = locationResponse.data;

      setProducts(productData);
      setCustomers(customerData);
      setLocations(locationData);

      setdata(productData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!locationFilters || !productFilters || !customerFilters) {
      fetchData();
    } else {
      setProducts(productFilters);
      setCustomers(customerFilters);
      setLocations(locationFilters);
      setdata(productFilters); // Set default data to products for example
    }
  }, [locationFilters, productFilters, customerFilters]);

  const dataChanger = (val) => {
    if (val === 'products') {
      setdata(products);
      setSelectedListItem(null);
    } else if (val === 'customers') {
      setdata(customers);
      setSelectedListItem(null);
    } else if (val === 'locations') {
      setdata(locations);
      setSelectedListItem(null);
    }
  };



  return (
    <PortfolioContext.Provider value={{
      loading,
      data,
      selectedChip,
      setSelectedChip,
      selectedTab,
      setSelectedTab,
      dataChanger,
      selectedListItem,
      setSelectedListItem,
      products,
      customers,
      locations,
      disContinueForm,
      setDisContinueForm,
      oldTransitionForm,
      setOldTransitionForm,
      locationFilters,
      productFilters,
      customerFilters
    }}>
      {children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolio = () => useContext(PortfolioContext);
