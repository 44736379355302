import React from 'react'
import { useUpload } from '../../../Layout/baseContext/uploadContext/UploadContext';
import { Button } from 'antd';
import { IoMdArrowRoundBack } from "react-icons/io";
import ConfigForm from './ConfigForm';


const Configure = ({setConfigurePage}) => {
    const {  selectedCategory, configBox } = useUpload();
    return (
        <div className='flex flex-col w-full items-center text-xs text-bodyTextBlue'>
            <div className='flex gap-6 items-center  w-full '>
                <div className={`py-1 font-semibold`}>
                    <Button onClick={() => setConfigurePage(false)} className='p-0 text-base text-slate-400' type='link'><IoMdArrowRoundBack/>Go back</Button>
                </div>

                <div className={`py-1 font-semibold text-lg`}>{`${selectedCategory[0]} > ${configBox} > Configure`}</div>
            </div>
            <div className='flex flex-wrap w-full gap-4 pt-4 '>
                <ConfigForm/>
            </div>

        </div>

    )
}

export default Configure