import {  InfoCircleOutlined} from '@ant-design/icons'
import React from 'react'

const InsightsCard = ({insightName}) => {
  return (
    <div className='w-full flex flex-col rounded-xl shadow-md bg-white p-4 space-y-4'>
        <div className='flex flex-row w-full justify-between items-center py-2'>
            <p className='text-lg'>{insightName}</p>
            <InfoCircleOutlined className='text-blue-500'/>
        </div>
        <div className='flex flex-col w-full space-y-2 p-2 items-center'>
            <div className='flex flex-row text-gray-400  font-light justify-between w-full'>
                <p>Segment</p>
                <p>Engine</p>
                <p>Planner</p>
            </div>
            <div className='flex flex-row px-2 py-4 bg-gray-100 rounded-lg font-light justify-between w-full'>
                <p>Segment</p>
                <p>Engine</p>
                <p>Planner</p>
            </div>
            <div className='flex flex-row px-2 py-4 bg-gray-100 rounded-lg font-light justify-between w-full'>
                <p>Segment</p>
                <p>Engine</p>
                <p>Planner</p>
            </div>
            <div className='flex flex-row px-2 py-4 bg-gray-100 rounded-lg font-light justify-between w-full'>
                <p>Segment</p>
                <p>Engine</p>
                <p>Planner</p>
            </div>
        </div>
    </div>
  )
}

export default InsightsCard