import React, { useEffect } from "react";

const InsightsOverlay = ({ onClose, onSubmit, insights,setInsights }) => {

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    
    var newMap=new Map([...insights]);
    if (checked) {
      newMap.set(value,true);
    } else {
      newMap.set(value,false);
    }
    setInsights(newMap);
  };

  useEffect(()=>{
    
  },[])

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Select Options</h2>
        <div className="grid grid-cols-3 gap-4">
          {Array.from(insights.keys()).map((item, index) => (
            <div key={index}>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  value={item}
                  defaultChecked={insights.get(item)}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-indigo-600 h-5 w-5"
                />
                <span className="ml-2">{item}</span>
              </label>
            </div>
          ))}
        </div>
        <div className="mt-6">
          <button
            onClick={() => {
              onSubmit();
              onClose();
            }}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsightsOverlay;
