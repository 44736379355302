import React from 'react'
import { Select, Button } from "antd"
import { Input } from 'antd';

const FormContainer = () => {
    return (
        <form className='flex flex-col gap-2 basis-1/2  px-8 my-4 '>
            <div className='flex flex-row gap-3 items-end flex-wrap'>
                <div className=' flex-grow  flex flex-col'>
                    <label htmlFor="alternate-units" className='text-gray-400 text-xs'>
                        Suggest Alternate no of units:
                    </label>
                    <Input
                        type="text"
                        id="alternate-units"
                        className='mt-2 '
                    />
                </div>
                <div className='flex-grow  flex flex-col'>
                    <label htmlFor="notify-select" className='text-gray-400 text-xs'>
                        Notify
                    </label>
                    <Select
                        id="notify-select"
                        defaultValue={{ value: 'Everyone', label: 'everyone' }}
                        className=' mt-2 '
                        options={[
                            { value: 'Everyone', label: 'everyone' },
                            { value: 'Specific', label: 'specific' },
                            { value: 'No one', label: 'noone' },
                        ]}
                    />
                </div>
            </div>
            <div>
                <label>
                    <Input
                        type="text"
                        placeholder='Type your justification here '
                    />
                </label>
            </div>
            <div className='flex justify-end'>
                <Button type='primary' className='bg-blue-500'>Submit Modifications</Button>
            </div>
        </form>
    )
}

export default FormContainer