import React, { createContext, useState, useContext, useEffect } from 'react';
import { useBase } from '../BaseContext';
import { message } from 'antd';
import api from '../../../../api';

const SupplyContext = createContext();

export const SupplyProvider = ({ children }) => {

    const [supFields, setsupFields] = useState({
    "base": "BOH",
    "base_2": "EOH",
    "base_3": "POERQTY",
    "base_4": "STERQTY",
    "base_5": "PRQTY",
    "interval": "FDATE",
    "item": "ITEM",
    "location": "LOCATION",
  })

  const [supplyData, setSupplyData] = useState([])
  const [supplyIntervals, setsupplyIntervals] = useState([])
    
    const getSupplyData = async () => {
        try{
            const response = await api.get("/api/supplyCube/?fields=" + supFields["item"] + "," + supFields["location"] + "," + supFields["interval"] + "," + supFields["base_2"] + "," + supFields["base"] + "," + supFields["base_3"] + "," + supFields["base_4"] + "," + supFields["base_5"] );
            setSupplyData(response.data.results)
            
        }
        catch(e){
            console.log(e);
        }
    }


    const getSupplyIntervals = async () => {
        try {
    
    
          const response = await api.get("/api/supplyCube/?fields=" + supFields["interval"] );
          const responseData = response.data;
    
          setsupplyIntervals(responseData.results);
    
          
    
        } catch (error) {
          console.log(error.message);
        }
      }
    useEffect(() => {
        getSupplyIntervals();
        getSupplyData();
    },[])
  return (
    <SupplyContext.Provider value={{
        supplyData, setSupplyData,
        supFields, setsupFields,
        supplyIntervals, setsupplyIntervals
    }}>
      {children}
    </SupplyContext.Provider>
  );
};

export const useSupply = () => useContext(SupplyContext);