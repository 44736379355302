import React from 'react'
import { useUpload } from '../../../Layout/baseContext/uploadContext/UploadContext'
import { Button } from 'antd';
import { FaExternalLinkAlt } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { RiDatabase2Line } from "react-icons/ri";
import { MdOutlineEmojiEvents } from "react-icons/md";


const FirstUploadPage = ({ setFirstPage }) => {
    const { categories, setselectedCategory } = useUpload();


    const handleCardClick = (cat) => {
        setFirstPage(false)
        setselectedCategory(cat)
    }
    return (
        <div className='flex flex-col justify-center  h-full w-full items-center text-xs text-bodyTextBlue'>
            <div className=' flex flex-col gap-6 items-center border bg-white box-shadow p-20  rounded-lg'>
                <h1 className='text-4xl'>Upload Data to make it easy for you</h1>
                <h3 className='text-2xl text-slate-400'>Select a category</h3>
                <div className='flex gap-8 justify-between'>
                    {
                        Object.entries(categories).map((cat) => (
                            <div onClick={() => handleCardClick(cat)} className='bg-slate-50 w-52 h-28 whitespace-nowrap cursor-pointer flex flex-col gap-2 justify-center items-center text-xl  rounded-lg box-shadow hover:scale-110  transition-all duration-200 '>
                                {
                                    cat[0] === 'Master Data' &&
                                    <div className='text-3xl'>
                                        <RiDatabase2Line />
                                    </div>
                                }
                                 {
                                    cat[0] === 'Transactions' &&
                                    <div className='text-3xl'>
                                        <GrTransaction />
                                    </div>
                                }
                                 {
                                    cat[0] === 'Initiatives' &&
                                    <div className='text-3xl'>
                                        <MdOutlineEmojiEvents />
                                    </div>
                                }
                                 {
                                    cat[0] === 'External' &&
                                    <div>
                                        <FaExternalLinkAlt />
                                    </div>
                                }
                                <p>
                                    {cat[0]}

                                </p>

                            </div>
                        ))
                    }
                </div>
                <div className='text-lg'>
                    Or
                </div>
                <div>
                    <Button type='primary'>Download all templates</Button>
                </div>
            </div>
        </div>
    )
}

export default FirstUploadPage