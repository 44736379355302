import React from 'react';

const LoadingOverlay = ({ isLoading, children }) => {
  return (
    <div className="relative">
      {children}
      {isLoading && (
        <div
          className="fixed inset-0 flex justify-center items-center z-50 bg-gray-500 bg-opacity-50"
        >
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-300"></div>
        </div>
      )}
    </div>
  );
};

export default LoadingOverlay;
