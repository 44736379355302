import { Layout } from 'antd'
import React from 'react'
import HeaderComponent from '../Layout/Header/HeaderComponent'
import { Content } from 'rsuite'
import SupplyView from './components/supplyView/SupplyView'

const SupplyViewBase = ({
    setNext,
    callingAPI,
    setPage,
    page,
    adjLoading,
    setAdjLoading,
    filterString,
    locationFilters,
    productFilters,
    customerFilters,
    getData,
    getGraphData,
    columnsShown,
    setColumnShown,
    setCheckBoxString,
    graphLoading,
    tdateVar,
    startDate,
    setStartDate,
    intervalList,
    graphData,
    fields,
    setFields,
    planningData,
    tableLoading,
    additionalFields,
    setFilterString,
    setSelectedArea,
    uiMappingData, handleRefresh
}) => {
    return (
        <Layout className="h-full w-full" style={{ background: "#F9F9FC" }}>
            <header
                className="bg-[#f9f9fc] w-full h-full z-30"
            >
                {
                    Object.keys(locationFilters).length > 0 && Object.keys(productFilters).length > 0 && Object.keys(customerFilters).length > 0
                        ?
                        <HeaderComponent uiMappingData={uiMappingData} filterString={filterString} setFilterString={setFilterString} locationFilters={locationFilters} productFilters={productFilters} customerFilters={customerFilters} setSelectedArea={setSelectedArea} /> : null
                }

            </header>
            <Content
                className="content   w-full h-full overflow-scroll"
            >
                <SupplyView
                    setNext={setNext}
                    callingAPI={callingAPI}
                    page={page}
                    setPage={setPage}
                    adjLoading={adjLoading}
                    setAdjLoading={setAdjLoading}
                    filterString={filterString}
                    getData={getData}
                    getGraphData={getGraphData}
                    columnsShown={columnsShown}
                    setColumnShown={setColumnShown}
                    setCheckBoxString={setCheckBoxString}
                    graphLoading={graphLoading}
                    tdateVar={tdateVar}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    intervalList={intervalList}
                    graphData={graphData}
                    fields={fields}
                    setFields={setFields}
                    planningData={planningData}
                    tableLoading={tableLoading}
                    additionalFields={additionalFields}
                    handleRefresh={handleRefresh}
                    uiMappingData={uiMappingData}
                    setFilterString={setFilterString}
                    locationFilters={locationFilters}
                    productFilters={productFilters}
                    customerFilters={customerFilters} />
            </Content>
        </Layout>)
}

export default SupplyViewBase