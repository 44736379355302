import React, { createContext, useState, useContext, useEffect } from 'react';
import api from "../../../../api";
import { useBase } from '../BaseContext';
import { message } from 'antd';

const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const [discussion, setDiscussion] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [discussionLoading, setDiscussionLoading] = useState(false)
  const [comments, setComments] = useState([]);
  const [postingComment, setPostingComment] = useState(false)
  const { fields, filterString} = useBase();
  

  const fetchPosts = async () => {

    try {
      setLoading(true);

      const data = localStorage.getItem("posts");
      if (data) {
        const postData = JSON.parse(data);
        setPosts(postData)
        
        setSelectedPost(postData[0])
      }
      else {
        const response = await api.get("/api/posts/");

        // Axios response data is available in response.data
        const result = response.data;
      
        // Store the posts in localStorage
        localStorage.setItem("posts", JSON.stringify(result));
      
        // Update the state with the fetched posts
        setPosts(result);
        setSelectedPost(result[0])
      }
      

      // let defaultSelectedPost =  posts.find(post => post.STATUS === 'ongoing');
      // 
      // if (defaultSelectedPost !== undefined) {
      //   defaultSelectedPost = posts.find(post => post.STATUS === 'archived');
      // }
      // else if (defaultSelectedPost !== undefined) {
      //   defaultSelectedPost = posts.find(post => post.STATUS === 'old');
      // }
      // else {
      //   defaultSelectedPost = posts[0];
      // }
      
      // 
      // setSelectedPost(defaultSelectedPost)
      
      
      setLoading(false)
    }
    catch (error) {
      console.error('Error fetching posts:', error);
    }
  };
  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchComments = async (posting = false) => {
    try {
      // if(comments.length === 0){
        //   setDiscussionLoading(true);
      // }
      
      if (!posting) {
        setDiscussionLoading(true);
      }



      const response = await api.get("/api/comments/")

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const allComments = response.data;

      const selectedComments = allComments.filter((comment) => comment.POST_ID === selectedPost.id)
      
      setDiscussionLoading(false);
      
      
      setComments(selectedComments);
    } catch (error) {
      console.error('Error fetching discussion data:', error);
    }
  };

  useEffect(() => {
    fetchComments();
  },[selectedPost])



  const getPostGraph = async ()  => {
    try{
      // console.log(filterString);
      // const response = await api.get("/api/forecastcube/?fields=" + fields["interval"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + "&page=1" + filterString);
    }
    catch(e){
      message.info("Something went wrong");
    }
  }

  useEffect(() => {
    getPostGraph();
  },[selectedPost])


  return (
    <PostContext.Provider value={{ posts, setPosts, discussion, setDiscussion, selectedPost, setSelectedPost, loading, fetchComments, discussionLoading, setDiscussionLoading, comments, setComments, postingComment, setPostingComment }}>
      {children}
    </PostContext.Provider>
  );
};

export const usePost = () => useContext(PostContext);