import React from 'react'
import { HiOutlineClipboardList } from "react-icons/hi";
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';


const ProposalTable = ({
    trasnposedData,
    tdateVar,
    startDate,
    endDate,
    midIndex,
    tdRef,
    setDetailedProposal
}) => {

    const { proposalData } = usePlan()

    const propRows = proposalData.reduce((acc, current) => {
        if (!acc.find(item => item.PROJECT_ID === current.PROJECT_ID)) {
            acc.push(current);
        }
        return acc;
    }, []);

    const getColumnData = (projectID, date) => {
        const data = proposalData.find((item) => item.PROJECT_ID === projectID && item.EXPECTED_INVOICE_DATE === date)


        return data
    }

    // Function to calculate the sum of quantities for a given project ID
    const sumQuantitiesByProjectID = (projectId) => {
        return proposalData
            .filter(item => item.PROJECT_ID === projectId) // Filter rows with the given PROJECT_ID
            .reduce((sum, item) => sum + (item.QUANTITY || 0), 0); // Sum up the QUANTITY values, handling null as 0
    };
    // Function to calculate the sum of values for a given project ID
    const sumValuesByProjectID = (projectId) => {
        return proposalData
            .filter(item => item.PROJECT_ID === projectId) // Filter rows with the given PROJECT_ID
            .reduce((sum, item) => sum + (item.VALUE || 0), 0); // Sum up the QUANTITY values, handling null as 0
    };


    return (
        <table className=''>
            <thead>
                <tr className='bg-blue-100 font-semibold'>
                    <td className='bg-blue-100 shadow-col z-10 sticky left-0 min-w-[270px] max-w-[270px] font-semibold px-2 py-3'>
                        <div className='flex w-full justify-between items-center'>
                            <p>
                                Project ID
                            </p>
                            <p className='text-lg hover:text-blue-500 cursor-pointer' onClick={() => setDetailedProposal(true)}>
                                <HiOutlineClipboardList />
                            </p>
                        </div>
                    </td>
                    <td className='bg-blue-100 shadow-col z-10 sticky left-[270px] min-w-[180px] font-semibold px-2 py-3'>
                        Stage
                    </td>
                    <td className='bg-blue-100 shadow-col z-10 sticky left-[450px] min-w-[180px] font-semibold px-2 py-3'>
                        Success probability
                    </td>
                    <td className='bg-blue-100 shadow-col z-10 sticky left-[630px] min-w-[130px] max-w-[130px] font-semibold px-2 py-3 text-center'>
                        Total
                    </td>
                    {trasnposedData.size !== 0 && Object.keys(trasnposedData.get([...trasnposedData.keys()][0])).map((item, index) => {
                        if (item === tdateVar && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                            midIndex = index
                            return (<React.Fragment key={index}>
                                <td className='rounded-tl-sm shadow-col min-w-[110px] max-w-[110px]  text-center px-3 text-xs py-1 border-1 border-gray-200'>{item}</td>
                            </React.Fragment>)
                        }
                        else {
                            if (midIndex !== 0 && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate) && index === midIndex + 4) {
                                return (<React.Fragment key={index}>
                                    <td ref={tdRef} className='rounded-tl-sm min-w-[110px] max-w-[110px] shadow-col text-center px-3 text-xs py-1 border-1 border-gray-200'>{item}</td>
                                </React.Fragment>)
                            }
                            else if (new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                return (<React.Fragment key={index}>
                                    <td className='rounded-tl-sm text-center min-w-[110px] max-w-[110px] shadow-col px-3 text-xs py-1 border-1 border-gray-200'>{item}</td>
                                </React.Fragment>)
                            }
                        }
                    })}
                </tr>
            </thead>
            <tbody className='w-full'>

                {proposalData.length === 0 ?
                    <tr className='bg-slate-10'>
                        <td className='!border-none sticky  z-10 left-0  min-w-[270px] max-w-[270px]   px-2 py-5'>
                            No Data
                        </td>                
                    </tr>
                    :
                    propRows.map((proposal, index) => (

                        <tr className='bg-slate-100'>
                            <td className='bg-blue-50 sticky shadow-col z-10 left-0 min-w-[270px] max-w-[270px]   px-2 py-2 text-blue-500'>
                                {proposal.PROJECT_ID}
                            </td>
                            <td className='bg-blue-50 sticky shadow-col z-10 left-[270px] min-w-[180px]  px-2 py-2'>
                                {proposal.STAGE}
                            </td>
                            <td className='bg-blue-50 sticky shadow-col z-10 left-[450px] min-w-[180px]  px-2 py-2 text-green-500'>
                                {proposal.PROBABILITY} %
                            </td>
                            <td className='bg-blue-50 shadow-col z-10 sticky left-[630px] min-w-[130px] max-w-[130px] px-2 py-'>
                                <div className='flex w-full'>

                                    <p className='w-full text-center text-blue-600'>
                                        {Intl.NumberFormat('en-US', {
                                            notation: "compact",
                                            maximumFractionDigits: 1
                                        }).format(sumQuantitiesByProjectID(proposal.PROJECT_ID))}
                                    </p>
                                    <p className='w-full text-center text-green-600'>
                                        {Intl.NumberFormat('en-US', {
                                            notation: "compact",
                                            maximumFractionDigits: 1
                                        }).format(sumValuesByProjectID(proposal.PROJECT_ID))}
                                    </p>
                                </div>

                            </td>
                            {trasnposedData.size !== 0 && Object.keys(trasnposedData.get([...trasnposedData.keys()][0])).map((item, index) => (




                                <td className=' px-2 py-2 bg-blue-50 min-w-[110px] max-w-[110px]'>
                                    <div className='flex flex-row items-center justify-between w-full '>
                                        <span className='text-blue-600'>
                                            {
                                                getColumnData(proposal.PROJECT_ID, item) ?

                                                    Intl.NumberFormat('en-US', {
                                                        notation: "compact",
                                                        maximumFractionDigits: 1
                                                    }).format(getColumnData(proposal.PROJECT_ID, item).QUANTITY)
                                                    :
                                                    "-"
                                            }
                                        </span>
                                        <span className='text-green-600'>
                                            {
                                                getColumnData(proposal.PROJECT_ID, item) ?

                                                    Intl.NumberFormat('en-US', {
                                                        notation: "compact",
                                                        maximumFractionDigits: 1
                                                    }).format(getColumnData(proposal.PROJECT_ID, item).VALUE)
                                                    :
                                                    "-"
                                            }
                                        </span>                                </div>
                                </td>


                            ))}

                        </tr>
                    ))}

            </tbody>
        </table>
    )
}

export default ProposalTable