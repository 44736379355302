import React, { useEffect, useState } from 'react'
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext'

const CommonExpandedRow = ({
    name,
    additionalFields,
    trasnposedData,
    data,
    midIndex
}) => {
    const {showAverage, 
        showSum } = usePlan();
        const [colSpanNumber, setColSpanNumber] = useState(0);
        useEffect(() => {
            if(showAverage && showSum){
                setColSpanNumber(2)
            }
            else if(showAverage || showSum){
                setColSpanNumber(1)
            }
            else{
                setColSpanNumber(0)
            }
        },[showAverage,showSum])
    return (
        <tr className=' bg-slate-100 '>
            {
                additionalFields.length === 0 &&
                <td colSpan={4 + colSpanNumber} className='sticky p-1 min-w-[30px] shadow-col bg-violet-50 left-0 z-30  h-full  text-bodyTextBlue  pl-3 text-sm  '>{name}</td>
            }
            {
                additionalFields.length !== 0 &&
                <td colSpan={additionalFields.length + 1 + colSpanNumber} className='sticky p-1 min-w-[30px] bg-violet-50 shadow-col  left-0 z-30  h-full text-bodyTextBlue  pl-3 text-sm  '>{name}</td>
            }


            {trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {

                {
                    if (name === "Open Orders") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>{item.OPEN_OQTY === null ? 0 : item.OPEN_OQTY}</td>
                        )
                    }
                    if(name === "Quotations"){
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>{item.QQTY === null ? 0 : item.QQTY}</td>
                        )
                    }
                    if(name === "POERQTY"){
                        return (
                            <td className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>{item.POERQTY === null ? 0 : item.POERQTY}</td>
                        )
                    }
                    else{
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>0</td>
                        )
                    }
                }
            })}
            {/* <td className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>0</td> */}

        </tr>)
}

export default CommonExpandedRow



