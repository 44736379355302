import { Button } from 'antd'
import React, { useEffect } from 'react'
import defaultActivity from '../../../components/assets/img/defaultActivity.png'
import { useDashboard } from '../../Layout/baseContext/dashboardContext/DashboardContext'
import { usePost } from '../../Layout/baseContext/postContext/PostContext'
import { useBase } from '../../Layout/baseContext/BaseContext'
import { usePortfolio } from '../../Layout/baseContext/portfolioContext/PortfolioContext'
import { usePlan } from '../../Layout/baseContext/planningContext/PlanningContext'
import { IoCloseSharp } from "react-icons/io5";

const ActivityHistory = () => {

  const { sortedActivities, setShowSeeAll, showSeeAll } = useDashboard();
  const { setSelectedPost, fetchComments, setDiscussion } = usePost()
  const { setSelectedMenu, setFields, setFilterString } = useBase()
  const { setSelectedArea } = usePlan()
  const { setSelectedListItem, setDisContinueForm, setOldTransitionForm, selectedListItem } = usePortfolio()


  const formatTimeDiff = (updatedAt) => {
    const now = new Date();
    const updateTime = new Date(updatedAt);
    const diff = now - updateTime; // difference in milliseconds

    const diffInMinutes = Math.floor(diff / 60000); // convert to minutes
    const diffInHours = Math.floor(diffInMinutes / 60); // convert to hours
    const diffInDays = Math.floor(diffInHours / 24); // convert to days

    if (diffInMinutes < 60) {
      return `${diffInMinutes === 1 ? '1 minute' : `${diffInMinutes} minutes`} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours === 1 ? '1 hour' : `${diffInHours} hours`} ago`;
    } else {
      return `${diffInDays === 1 ? '1 day' : `${diffInDays} days`} ago`;
    }
  };
  const formattedActivities = sortedActivities.map(activity => ({
    ...activity,
    updatedAt: formatTimeDiff(activity.updatedAt)
  }));


  const handleViewPost = (act) => {
    setSelectedMenu('details')
    setSelectedArea('post')
    setSelectedPost(act)
  }

  const handleViewComment = (id) => {
    const posts = JSON.parse(localStorage.getItem("posts"))
    const post = posts.find((post) => post.id === id)

    setSelectedMenu('details')
    setSelectedArea('post')
    setSelectedPost(post)
    fetchComments();
    setDiscussion(true)
  }
  const handleViewPortfolio = (act) => {
    setSelectedMenu('details')
    setSelectedArea('portfolio')

    if (act.activityType === 'discontinue portfolio' || act.activityType === 'transition portfolio') {
      const products = JSON.parse(localStorage.getItem("productFilters"))
      const item = products.find((product) => product.ITEM === act.ITEM)
      setSelectedListItem(item)
      setDisContinueForm(false)
      setOldTransitionForm(false)
    }
  }

  const handleViewAdjustment = (act) => {

    setFields((prev) => ({
      ...prev,
      item: act.ITEM_LEVEL,
      location: act.LOCATION_LEVEL,
      category: act.CUSTOMER_LEVEL
    }));


    const fstring = "&" + act.ITEM_LEVEL + "=" + act.ITEM_VALUE + ",&" + act.LOCATION_LEVEL + "=" + act.LOCATION_VALUE + ",&" + act.CUSTOMER_LEVEL + "=" + act.CUSTOMER_VALUE
    setFilterString(encodeURI(fstring))
    setSelectedMenu('details')
    setSelectedArea('planning')
  };





  return (



    <div className='py-4 h-full flex flex-col w-full'>
      <div className='flex flex-row mb-2 justify-between items-center px-4 '>
        <div className='flex gap-4 justify-between w-full  items-center'>

          <h3 className='text-sm font-semibold'>Activity History</h3>
       
        </div>
        {
          showSeeAll !== 'activity' &&
          <div>
            <Button onClick={() => setShowSeeAll("activity")} className='bg-blue-50 text-xs rounded-full hover:!bg-indigo-50' type='link'>See all</Button>
          </div>
        }
      </div>
      <div className='relative h-full w-full'>

        <div className='absolute overflow-y-scroll scroll h-full w-full'>
          <div className='flex flex-col h-full w-full'>

            {
              formattedActivities.map((act) => {
                if (act.activityType === 'comment') {
                  return (
                    <div onClick={() => handleViewComment(act.POST_ID)} className='mt-2 text-slate-400 flex flex-row  justify-between items-center px-4 py-2 cursor-pointer hover:bg-slate-50'>
                      <div className='flex flex-row '>
                        <div className='rounded-full min-w-7 max-w-7 pt-1'>
                          <img className='rounded-full' src={defaultActivity} alt="" />
                        </div>
                        <div className='ml-2'>
                          <div>
                            <span className='font-semibold text-slate-500 mr-1'>{act.BY_USER}</span>
                            <span>commented "{act.COMMENT}"</span>
                          </div>
                          <div className='text-blue-400'>{act.updatedAt}</div>
                        </div>
                      </div>
                      {/* <div>
                        <Button className='text-xs font-semibold' type='link'>View</Button>
                      </div> */}
                    </div>
                  )

                }
                if (act.activityType === 'discontinue portfolio') {
                  return (
                    <div onClick={() => handleViewPortfolio(act)} className='mt-2 text-slate-400 flex flex-row  justify-between items-center px-4 py-2 cursor-pointer hover:bg-slate-50'>
                      <div className='flex flex-row '>
                        <div className='rounded-full  min-w-7 max-w-7 pt-1'>
                          <img className='rounded-full' src={defaultActivity} alt="" />
                        </div>
                        <div className='ml-2'>
                          <div>
                            <span className='font-semibold text-slate-500 mr-1'>UserID-{act.USER_ID}</span>
                            <span>discontinued Item-{act.ITEM} Location-{act.LOCTION} Customer-{act.CUSTOMER}</span>
                          </div>
                          <div className='text-blue-400'>{act.updatedAt}</div>
                        </div>
                      </div>
                      {/* <div>
                        <Button className='text-xs font-semibold' type='link'>View</Button>
                      </div> */}
                    </div>
                  )

                }
                if (act.activityType === 'adjustment') {
                  return (
                    <div className='mt-2 text-slate-400 flex flex-col px-4 py-2   hover:bg-slate-50 '>
                      <div className='flex flex-row '>
                        <div className='rounded-full  min-w-7 max-w-7 pt-1'>
                          <img className='rounded-full' src={defaultActivity} alt="" />
                        </div>
                        <div className='ml-2'>
                          <div>
                            <span className='font-semibold text-slate-500 mr-1'>{act.BY_USER}</span>
                            <span>adjusted Item-{act.ITEM_VALUE} Location-{act.LOCATION_VALUE} Customer-{act.CUSTOMER_VALUE} previous value-{act.PREVIOUS_VALUE} new value-{act.NEW_VALUE}</span>
                          </div>
                          <div className='text-blue-400'>{act.updatedAt}</div>
                          <div className='flex gap-4 p-0 '>
                            <Button onClick={() => handleViewPost(act)} className='text-xs font-semibold p-0 m-0 cursor-pointer' type='link'>View Post</Button>
                            <Button onClick={() => handleViewAdjustment(act)} className='text-xs font-semibold p-0 m-0 cursor-pointer' type='link'>View Table</Button>
                          </div>
                        </div>
                      </div>

                      {/* <div>
                        <Button className='text-xs font-semibold' type='link'>View</Button>
                      </div> */}
                    </div>
                  )

                }
                if (act.activityType === 'transition portfolio') {
                  return (
                    <div onClick={() => handleViewPortfolio(act)} className='mt-2 text-slate-400 flex flex-row  justify-between items-center px-4 py-2 cursor-pointer hover:bg-slate-50'>
                      <div className='flex flex-row '>
                        <div className='rounded-full  min-w-7 max-w-7 pt-1'>
                          <img className='rounded-full' src={defaultActivity} alt="" />
                        </div>
                        <div className='ml-2'>
                          <div>
                            <span className='font-semibold text-slate-500 mr-1'>UserID-{act.USER_ID}</span>
                            <span>Transition Item-{act.OLD_ITEM} Location-{act.OLD_LOCATION} Customer-{act.OLD_CUSTOMER}
                              {act.OLD_ITEM !== act.ITEM && ` New Item-${act.ITEM}`}
                              {act.OLD_LOCATION !== act.LOCATION && ` New Location-${act.LOCATION}`}
                              {act.OLD_CUSTOMER !== act.CUSTOMER && ` New Customer-${act.CUSTOMER}`}
                            </span>
                          </div>
                          <div className='text-blue-400'>{act.updatedAt}</div>
                        </div>
                      </div>
                      {/* <div>
                        <Button className='text-xs font-semibold' type='link'>View</Button>
                      </div> */}
                    </div>
                  )

                }
                if (act.activityType === 'new portfolio') {
                  return (
                    <div className='mt-2 text-slate-400 flex flex-row  justify-between items-center px-4 py-2 cursor-pointer hover:bg-slate-50'>
                      <div className='flex flex-row '>
                        <div className='rounded-full  min-w-7 max-w-7 pt-1'>
                          <img className='rounded-full' src={defaultActivity} alt="" />
                        </div>
                        <div className='ml-2'>
                          <div>
                            <span className='font-semibold text-slate-500 mr-1'>UserID-{act.USER_ID}</span>
                            <span>New Item-{act.ITEM} Location-{act.LOCTION} Customer-{act.CUSTOMER}</span>
                          </div>
                          <div className='text-blue-400'>{act.updatedAt}</div>
                        </div>
                      </div>
                      {/* <div>
                        <Button className='text-xs font-semibold' type='link'>View</Button>
                      </div> */}
                    </div>
                  )

                }

              })

            }

          </div>
        </div>
      </div>
    </div>

  )

}

export default ActivityHistory