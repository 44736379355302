import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import BaseLayout from "./components/Layout/Baselayout";
import { useEffect } from "react";
import Login from "./pages/Login"
import Register from "./pages/Register"
import NotFound from "./pages/NotFound"
import ProtectedRoute from "./components/ProtectedRoute"
import CombinedContextProvider from "./components/Layout/baseContext/CombinedContext";
import { BaseProvider } from "./components/Layout/baseContext/BaseContext";

// import Home from "./pages/Home";

function Logout() {
  localStorage.clear()
  return <Navigate to="/login" />
}

function RegisterAndLogout() {
  localStorage.clear()
  return <Register />
}

function App() {
  useEffect(() => {

  });



  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <BaseProvider>
                <CombinedContextProvider>
                  <BaseLayout />
                </CombinedContextProvider>
              </BaseProvider>
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<RegisterAndLogout />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
