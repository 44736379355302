import React, { useEffect, useState } from 'react';
import { useAlert } from '../../Layout/baseContext/alertsContext/AlertsContext';
import { Button } from 'antd';
import LineChart from "./LineChart";
import { MdOutlineExpandCircleDown } from "react-icons/md";
import DynamicForm from "./DyanmicForm";
import { FaArrowDownLong } from "react-icons/fa6";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import UnresolvedPopUp from './UnresolvedPopUp';
import ResolveLFUPopUp from './ResolveLFUPopUp';
import { TailSpin } from 'react-loader-spinner';


const AlertsTable = () => {
    const {
        expandedRow, setExpandedRow,
        expandType, setExpandType,
        lfuExpandedIndex, setLfuExpandedIndex,
        editableLFU, setEditableLFU,
        editableSDATE, setEditableSDATE,
        editableValue, setEditableValue,
        editedLFUs, setEditedLFUs,
        comboExpand, setComboExpand,
        rowSpan, setRowSpan,
        LFUResolvePop, setLFUResolvePop,
        editingDFUIndex, seteditingDFUIndex, DFUResolve,
        editedRecords, setEditedRecords,
        updatedRecords, sendUpdatedRecords, unResPop, setunResPop, setUpdatedRecords, alertsData, sortingParameter, setAlertsData, setSortingParameter, sortDataByParam, isExpanded, setHighlightDate, setIsExpanded, selectedTable, setSelectedTable, selectedIndex, setSelectedIndex, tableHeaders, setalertsGraphData, alertsGraphData,
        //new states
        getLFUs,
        currentLFUs,
        lfuLoading,
        originalAlertsData,
        getAlertGraph,
        setcurrentLFUs,
        getAlertGraphDFU
    } = useAlert();

 

    const [currentValue, setCurrentValue] = useState('');
    const [dcDFUIndex, setDcDFUIndex] = useState(null)
    const toggleExpandRow = (rowIndex) => {
        if (rowSpan > 0) {
            setunResPop(true);
            return
        }
        setLfuExpandedIndex(null)
        setSelectedIndex(false)
        setIsExpanded(false)
        const selectedRow = selectedTable[rowIndex];
        const { DFU, SDATE } = selectedRow;

        // Check if the row is already expanded
        if (expandedRow === rowIndex) {
            setExpandedRow(null); // Collapse the row if it's already expanded
        } else {
            // Filter the alertsData to get all records matching the DFU and SDATE
            // const filteredData = alertsData.filter(data =>
            //     data.DFU === DFU &&
            //     data.SDATE === SDATE
            // );
            getLFUs(SDATE, DFU)
            setExpandedRow(rowIndex); // Expand the new row
        }
    };


    const toggleAccordion = (rowIndex, type, lfu, lfuIndex, sdate) => {
        setExpandType(type)
        setSelectedIndex(rowIndex);
        setIsExpanded(true);
        setHighlightDate(sdate)
        if (type === 'lfu') {
            setLfuExpandedIndex(lfuIndex)
            getAlertGraph(lfu);

        }
    };

    const toggleAccordionDfu = (index, data, type) => {
        setLfuExpandedIndex(null)
        setExpandType(type);
        setExpandedRow(null);
        setIsExpanded(true);
        setSelectedIndex(index)
        setHighlightDate(data.SDATE);
        getAlertGraphDFU(data.DFU, data.SDATE)

        // // Filter data to get all relevant LFUs associated with the selected DFU
        // const relatedLfus = alertsData.filter(
        //     (item) => item.DFU === data.DFU && item.SDATE === data.SDATE
        // );
        // console.log("related lfu -", relatedLfus);
        // // Create an object to hold the summed SQTY_LFU for each SDATE
        // const graphData = relatedLfus.reduce((acc, item) => {
        //     // Filter the alertsData to get occurrences of the current LFU
        //     const occurrences = alertsData.filter(alert => alert.LFU === item.LFU);
        //     console.log("occurences", occurrences);
        //     // Sum up SQTY_LFU values for each SDATE
        //     occurrences.forEach(alert => {
        //         if (!acc[alert.SDATE]) {
        //             acc[alert.SDATE] = 0;
        //         }
        //         acc[alert.SDATE] += alert.SQTY_LFU;
        //     });

        //     return acc;
        // }, {});

        // console.log(graphData);
        // setalertsGraphData(graphData);
    };


    const sortingData = (item) => {
        const sortedData = sortDataByParam(selectedTable, item)
        setSelectedTable(sortedData)
    }



    const sorting = (item) => {
        setSortingParameter(item)
        var param;
        if (item === 'Actuals') {
            param = 'SQTY_LFU'
        }
        if (item === 'Engine Resolved Quantity') {
            param = 'engineResolvedQuantity'
        }
        if (item === 'User Resolved Quantity') {
            param = 'SQTY_CORRECTED_USER'
        }
        const sorted = [...selectedTable].sort((a, b) => b[param] - a[param]);


        // Set the new sorted array as the state
        setSelectedTable(sorted);
    }
    

    const handleEdit = (expandedRow) => {
        const { LFU, SDATE, SQTY_CORRECTED_USER } = expandedRow;

        // Set the editable values
        setEditableLFU(LFU);
        setEditableSDATE(SDATE);
        setEditableValue(SQTY_CORRECTED_USER || "");

        // Only move the LFU to the top when typing starts
        if (!editedLFUs.includes(LFU)) {
            setRowSpan(rowSpan + 1);
            setEditedLFUs(prevEditedLFUs => [...prevEditedLFUs, LFU]);
        }
        // Check if the LFU is already in the edited list
        if (!editedLFUs.includes(LFU)) {
            setEditedLFUs(prevEditedLFUs => [...prevEditedLFUs, LFU]);

            // Reorder currentLFUs
            setcurrentLFUs(prevCurrentLFUs => {
                // Remove the edited row from its original position
                const filteredLFUs = prevCurrentLFUs.filter(data => data.LFU !== LFU);

                // Insert the edited row after already edited rows
                const reorderedLFUs = [
                    ...filteredLFUs.slice(0, editedLFUs.length), // Keep already edited rows at the top
                    { ...expandedRow }, // Insert the newly edited row
                    ...filteredLFUs.slice(editedLFUs.length) // Append the remaining rows
                ];

                return reorderedLFUs;
            });
        }
    };

    const handleTyping = (e) => {
        const value = e.target.value;
        setEditableValue(value);

        // Check if the LFU is already in the edited list
        if (!editedLFUs.includes(editableLFU)) {
            setEditedLFUs(prevEditedLFUs => [...prevEditedLFUs, editableLFU]);

            // Reorder currentLFUs
            setcurrentLFUs(prevCurrentLFUs => {
                const filteredLFUs = prevCurrentLFUs.filter(data => data.LFU !== editableLFU);
                const reorderedLFUs = [
                    ...filteredLFUs.slice(0, editedLFUs.length),
                    { ...prevCurrentLFUs.find(data => data.LFU === editableLFU) },
                    ...filteredLFUs.slice(editedLFUs.length)
                ];

                return reorderedLFUs;
            });
        }
    };

    // Ensure alertsData is ordered based on editedLFUs
    const reorderedAlertsData = currentLFUs.sort((a, b) => {
        const indexA = editedLFUs.indexOf(a.LFU);
        const indexB = editedLFUs.indexOf(b.LFU);
        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
    });

    setcurrentLFUs(reorderedAlertsData);



    const handleLfuResolve = () => {
        sendUpdatedRecords();
        setRowSpan(0);
        setIsExpanded(false);
        setLfuExpandedIndex(null);
        setComboExpand(false)
        setEditedLFUs([]);
    }


    const handleDFUclick = async (rowIndex) => {

        const rowData = selectedTable[rowIndex];
        const LFU = await getLFUs(rowData.SDATE, rowData.DFU, true)

        let hasPositive = false;
        let hasNegative = false;

        LFU.data.forEach(item => {
            if (item.engine_resolved_quantity > 0) {
                hasPositive = true;
            } else if (item.engine_resolved_quantity < 0) {
                hasNegative = true;
            }

            // If both positive and negative values are found, no need to check further
            if (hasPositive && hasNegative) {
                setDcDFUIndex(rowIndex)
                setLFUResolvePop(true);
                return; // Exit the loop early
            }
        });

        // If the loop completes without finding both positive and negative values
        if (!hasPositive || !hasNegative) {
            setLFUResolvePop(false);
            seteditingDFUIndex(rowIndex);
            setCurrentValue(rowData.SQTY_CORRECTED_USER || ''); // Initialize input value
        }
    

    };
    const handleInputChange = (e) => {
        setCurrentValue(e.target.value);
    };

    const onBlurDFU = () => {
        const rowIndex = editingDFUIndex;
        if (rowIndex !== null) {
            const newValue = currentValue;

            // Update selectedTable
            setSelectedTable(prev =>
                prev.map((item, index) =>
                    index === rowIndex ? { ...item, sqty_corrected_user: newValue } : item
                )
            );

            // Update editedRecords
            setEditedRecords(prev => {
                const existingRecordIndex = prev.findIndex(
                    record => record.dfu === selectedTable[rowIndex].DFU && record.sdate === selectedTable[rowIndex].SDATE
                );

                if (existingRecordIndex >= 0) {
                    // Update existing record
                    const updatedRecord = {
                        ...prev[existingRecordIndex],
                        sqty_corrected_user: newValue
                    };

                    return [
                        ...prev.slice(0, existingRecordIndex),
                        updatedRecord,
                        ...prev.slice(existingRecordIndex + 1)
                    ];
                } else {
                    // Add new record
                    return [
                        ...prev,
                        {
                            dfu: selectedTable[rowIndex].DFU,
                            sdate: selectedTable[rowIndex].SDATE,
                            sqty_corrected_user: newValue
                        }
                    ];
                }
            });

            seteditingDFUIndex(null); // End editing
            setCurrentValue(''); // Reset current value
        }
    };





    return (
        <div className='flex flex-col h-full overflow-y-scroll shadow-col scroll '>
            <table className='w-full whitespace-nowrap text-xs shadow-col  overflow-scroll'>
                <thead className='w-full sticky top-0 z-10 bg-slate-100 shadow-class shadow-col border'>
                    <tr className='font-bold whitespace-normal border shadow-col'>
                        {tableHeaders.map((item, index) => {
                            if (item === "LFU") {
                                return null; // Skip rendering for LFU
                            }
                            if (item === "Actuals" || item === "Engine Resolved Quantity" || item === "User Resolved Quantity") {
                                return (
                                    <td key={index} className='rounded-tl-sm bg-slate-100 text-center  items-center px-3 shadow-col z-10  text-xs py-1  border-gray-200 w-min'>
                                        <div onClick={() => sorting(item)} className={`flex gap-1 items-center cursor-pointer hover:text-indigo-500 ${sortingParameter === item ? "text-indigo-500" : ""}`}>

                                            <p>{item}</p>
                                            <i><FaArrowDownLong /></i>
                                        </div>
                                    </td>
                                );
                            } else {
                                return (
                                    <td key={index} className='rounded-tl-sm bg-slate-100 text-center  px-3 shadow-col z-10  text-xs py-1 border-gray-200 w-min'>
                                        {item}
                                    </td>
                                );
                            }
                        })}
                    </tr>

                </thead>
                <tbody className='w-full'>
                    {selectedTable.length !== 0 && selectedTable.map((data, rowIndex) => {
                        const isRowExpanded = expandedRow === rowIndex;

                        return (
                            <React.Fragment key={rowIndex}>
                                <tr className={` ${isRowExpanded || rowIndex === selectedIndex ? "bg-indigo-200" : ""}`}>
                                    <td className=''>
                                        {
                                            lfuLoading && isRowExpanded && expandedRow === rowIndex ?
                                                <div className='h-full flex justify-center items-center'>
                                                    <TailSpin
                                                        visible={true}
                                                        height="18"
                                                        width="18"
                                                        color="#2B3674"
                                                        ariaLabel="tail-spin-loading"
                                                        radius="1"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                    />
                                                </div>
                                                :
                                                <button className='text-slate-600 z-0 px-1 hover:text-blue-500 text-base pt-1' onClick={() => toggleExpandRow(rowIndex)}>
                                                    <div className={`transition-all ease-in-out z-0 duration-500 ${isRowExpanded ? 'rotate-180' : ''}`}>
                                                        <MdOutlineExpandCircleDown />
                                                    </div>
                                                </button>
                                        }
                                    </td>
                                    <td className='pl-3 max-w-[350px] overflow-hidden'>{data.DFU}</td>
                                    <td className='pl-3'>{data.SDATE}</td>
                                    {/* <td className='pl-3'>Classification</td> */}
                                    {/* <td className='pl-3'>Alert Type</td> */}
                                    {/* <td className='pl-3'>0</td> */}
                                    {/* <td className='pl-3'>0</td> */}
                                    <td className='pl-3'>{data.sqty_lfu}</td>
                                    <td className='pl-3'>
                                        {

                                            <p>{Number(data.engine_resolved_quantity).toFixed(2)}</p>
                                        }

                                    </td>
                                    <td className='px-3' onDoubleClick={() => handleDFUclick(rowIndex)}>
                                        {
                                            editingDFUIndex === rowIndex ? (
                                                <input
                                                    type="text"
                                                    value={currentValue}
                                                    onChange={handleInputChange}
                                                    onBlur={onBlurDFU} // Save changes on blur
                                                    className='w-full border-0 outline-none px-2 py-1 bg-indigo-0'
                                                    autoFocus
                                                />
                                            ) : (
                                                <span >
                                                    {data.sqty_corrected_user ? Number(data.sqty_corrected_user).toFixed(2) : 0}
                                                </span>
                                            )
                                        }
                                    </td>
                                    {/* <td className='pl-2'>
                                        <select name="abc" id="" className='bg-transparent'>
                                            <option value="1">Category 1</option>
                                            <option value="2">Category 2</option>
                                            <option value="3">Category 3</option>
                                        </select>
                                    </td> */}
                                    {/* <td>Event</td> */}
                                    <td className='text-left px-3 py-2 text-xs text-blue-500 border border-gray-200 cursor-pointer' onClick={() => toggleAccordionDfu(rowIndex, data, 'dfu')}>
                                        <Button  className='p-0 text-xs h-0 ' type='link'>Resolve</Button>
                                    </td>
                                </tr>

                                {isRowExpanded && expandedRow === rowIndex && currentLFUs.map((expandedRow, expandedIndex) => (
                                    <>

                                        <tr key={expandedIndex} className={` ${expandedIndex === lfuExpandedIndex ? "bg-red-100" : "bg-indigo-50"} ${comboExpand && expandedIndex < rowSpan ? "bg-red-100" : ""}`}>
                                            <td></td>
                                            <td className='pl-4' colSpan={2}>-LFU: {expandedRow.LFU}</td>
                                            {/* <td className='pl-3'>{expandedRow.Classification}</td> */}
                                            {/* <td className='pl-3'>{expandedRow.Alert_Type}</td> */}
                                            {/* <td className='pl-3'>0</td> */}
                                            {/* <td className='pl-3'>0</td> */}
                                            <td className='pl-3'>{expandedRow.SQTY_LFU}</td>
                                            <td className='pl-3'>
                                                {
                                                    expandedRow.engine_resolved_quantity > 0 &&
                                                    <p className='flex justify-between pr-4'>
                                                        <span>
                                                            {Number(expandedRow.engine_resolved_quantity).toFixed(2)}
                                                        </span>
                                                        <span className='text-green-500'>
                                                            <HiArrowNarrowUp />
                                                        </span>
                                                    </p>
                                                }
                                                {
                                                    expandedRow.engine_resolved_quantity < 0 &&
                                                    <p className='flex justify-between pr-4'>
                                                        <span>
                                                            {Number(Math.abs(expandedRow.engine_resolved_quantity)).toFixed(2)}
                                                        </span>
                                                        <span className='text-red-500'>
                                                            <HiArrowNarrowDown />
                                                        </span>
                                                    </p>
                                                }
                                                {
                                                    expandedRow.engine_resolved_quantity === 0 &&
                                                    <p className='flex pr-4'>
                                                        <span>0</span>
                                                    </p>
                                                }

                                            </td>
                                            <td className='' onDoubleClick={() => handleEdit(expandedRow)}>
                                                {
                                                    (editableLFU === expandedRow.LFU && editableSDATE === expandedRow.SDATE) ?
                                                        <input
                                                            autoFocus
                                                            className='w-full  border-0 outline-none px-2 py-1 bg-indigo-0'
                                                            value={editableValue}
                                                            onChange={handleTyping}
                                                            onBlur={() => {
                                                                const updatedAlertsData = currentLFUs.map(data =>
                                                                    data.LFU === editableLFU && data.SDATE === editableSDATE
                                                                        ? { ...data, SQTY_CORRECTED_USER: editableValue }
                                                                        : data
                                                                );
                                                                setcurrentLFUs(updatedAlertsData);

                                                                // Add to updated records
                                                                setUpdatedRecords(prevRecords => {
                                                                    const recordExists = prevRecords.some(
                                                                        record => record.LFU === editableLFU
                                                                    );
                                                                    if (recordExists) {
                                                                        return prevRecords.map(record =>
                                                                            record.LFU === editableLFU
                                                                                ? { ...record, SQTY_CORRECTED_USER: editableValue }
                                                                                : record
                                                                        );
                                                                    } else {
                                                                        return [...prevRecords, { LFU: editableLFU, SQTY_CORRECTED_USER: editableValue, SDATE: editableSDATE }];
                                                                    }
                                                                });

                                                                setEditableLFU(null);
                                                                setEditableSDATE(null);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    const updatedAlertsData = currentLFUs.map(data =>
                                                                        data.LFU === editableLFU && data.SDATE === editableSDATE
                                                                            ? { ...data, SQTY_CORRECTED_USER: editableValue }
                                                                            : data
                                                                    );
                                                                    setcurrentLFUs(updatedAlertsData);

                                                                    // Add to updated records
                                                                    setUpdatedRecords(prevRecords => {
                                                                        const recordExists = prevRecords.some(
                                                                            record => record.LFU === editableLFU
                                                                        );
                                                                        if (recordExists) {
                                                                            return prevRecords.map(record =>
                                                                                record.LFU === editableLFU
                                                                                    ? { ...record, SQTY_CORRECTED_USER: editableValue }
                                                                                    : record
                                                                            );
                                                                        } else {
                                                                            return [...prevRecords, { LFU: editableLFU, SQTY_CORRECTED_USER: editableValue }];
                                                                        }
                                                                    });

                                                                    setEditableLFU(null);
                                                                    setEditableSDATE(null);
                                                                }
                                                            }}
                                                        />
                                                        :
                                                        <p className='px-2'>
                                                            {Number(expandedRow.SQTY_CORRECTED_USER).toFixed(2) || 0}
                                                        </p>
                                                }
                                            </td>
                                            {/* <td className='pl-2'>
                                                <select name="abc" id="" className='bg-transparent'>
                                                    <option value="1">Category 1</option>
                                                    <option value="2">Category 2</option>
                                                    <option value="3">Category 3</option>
                                                </select>
                                            </td> */}
                                            {/* <td className='py-2'>Event</td> */}
                                            {
                                                expandedIndex === 0 && rowSpan === 0 &&
                                                <td className='text-left px-3 py-2 text-xs text-blue-500 border border-gray-200 cursor-pointer' onClick={() => { toggleAccordion(rowIndex, 'lfu', expandedRow.LFU, expandedIndex, expandedRow.SDATE); setComboExpand(false) }}>
                                                    <Button className='p-0  text-xs h-0' type='link'>Resolve</Button>
                                                </td>
                                            }
                                            {
                                                expandedIndex === 0 && rowSpan !== 0 &&
                                                <td rowSpan={rowSpan !== 0 && editedLFUs.includes(expandedRow.LFU) ? rowSpan : 1} className='text-left px-3 py-2 text-xs text-blue-500 border border-gray-200 cursor-pointer' onClick={() => { toggleAccordion(rowIndex, 'lfu', expandedRow.LFU, expandedIndex, expandedRow.SDATE); setComboExpand(true); }}>
                                                    <Button className='p-0  text-xs h-0' type='link'>Resolve</Button>
                                                </td>
                                            }
                                            {
                                                expandedIndex !== 0 && !editedLFUs.includes(expandedRow.LFU) &&
                                                <td className='text-left px-3 py-2 text-xs text-blue-500 border border-gray-200 cursor-pointer' onClick={() => { toggleAccordion(rowIndex, 'lfu', expandedRow.LFU, expandedIndex, expandedRow.SDATE); setComboExpand(false) }}>
                                                    <Button className='p-0  text-xs h-0' type='link'>Resolve</Button>
                                                </td>
                                            }


                                        </tr >

                                        {expandType === 'lfu' && isExpanded && rowIndex === selectedIndex && expandedIndex === lfuExpandedIndex && !comboExpand && (
                                            <tr className='bg-red-50  '>
                                                <td colSpan={10000}>
                                                    <div className='flex flex-row  gap-2 p-1 py-3 w-full h-full'>
                                                        <LineChart alertsGraphData={alertsGraphData} />
                                                        <div className='flex flex-col justify-between w-1/2 space-y-4 bg-white px-5 py-4 rounded-xl border shadow-sm'>
                                                            <div className='flex flex-col space-y-4'>
                                                                <h2 className='text-xl font-semibold text-bodyTextBlue'>Training Opportunity</h2>
                                                                <h5 className="text-slate-400">Current Selection shows 10343 units more than expected. The remaining unresolved quantity is 10361.</h5>
                                                                <DynamicForm changed_quantity={["Vendor Sales"]} changed_reason={["Low Sales"]} />
                                                            </div>
                                                            <div className='flex flex-col space-y-4 w-full'>
                                                                <h3 className='font-semibold text-slate-400'>*The Resolution Quantity will be disaggregated based on underlying deviation quantities.</h3>
                                                                <div className="flex flex-row items-center justify-end py-2 w-full space-x-6">
                                                                    <Button onClick={() => { setIsExpanded(false); setLfuExpandedIndex(null) }} type='link'>Cancel</Button>
                                                                    <Button className='' type='primary'>Resolve</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {expandType === 'lfu' && isExpanded && rowIndex === selectedIndex && expandedIndex === (rowSpan - 1) && comboExpand && (
                                            <tr className='bg-red-50  '>
                                                <td colSpan={10000}>
                                                    <div className='flex flex-row  gap-2 p-1 py-3 w-full h-full'>
                                                        <LineChart alertsGraphData={alertsGraphData} />
                                                        <div className='flex flex-col justify-between w-1/2 space-y-4 bg-white px-5 py-4 rounded-xl border shadow-sm'>
                                                            <div className='flex flex-col space-y-4'>
                                                                <h2 className='text-xl font-semibold text-bodyTextBlue'>Training Opportunity</h2>
                                                                <h5 className="text-slate-400">Current Selection shows 10343 units more than expected. The remaining unresolved quantity is 10361.</h5>
                                                                <DynamicForm changed_quantity={["Vendor Sales"]} changed_reason={["Low Sales"]} />
                                                            </div>
                                                            <div className='flex flex-col space-y-4 w-full'>
                                                                <h3 className='font-semibold text-slate-400'>*The Resolution Quantity will be disaggregated based on underlying deviation quantities.</h3>
                                                                <div className="flex flex-row items-center justify-end py-2 w-full space-x-6">
                                                                    <Button onClick={() => { setIsExpanded(false); setLfuExpandedIndex(null); setComboExpand(false) }} type='link'>Cancel</Button>
                                                                    <Button className='' type='primary' onClick={() => handleLfuResolve()}>Resolve</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}


                                    </>
                                ))}

                                {expandType === 'dfu' && isExpanded && rowIndex === selectedIndex && (
                                    <tr className={`${rowIndex === selectedIndex ? "bg-indigo-50" : ""}`}>
                                        <td colSpan={10000}>
                                            <div className='flex flex-row  gap-2 p-1 py-3 w-full h-full'>
                                                <LineChart alertsGraphData={alertsGraphData} />
                                                <div className='flex flex-col justify-between w-1/2 space-y-4 bg-white px-5 py-4 rounded-xl box-shadow'>
                                                    <div className='flex flex-col space-y-4'>
                                                        <h2 className='text-xl font-semibold text-bodyTextBlue'>Training Opportunity</h2>
                                                        <h5 className="text-slate-400">Current Selection shows 10343 units more than expected. The remaining unresolved quantity is 10361.</h5>
                                                        <DynamicForm changed_quantity={["Vendor Sales"]} changed_reason={["Low Sales"]} />
                                                    </div>
                                                    <div className='flex flex-col space-y-4 w-full'>
                                                        <h3 className='font-semibold text-slate-400'>*The Resolution Quantity will be disaggregated based on underlying deviation quantities.</h3>
                                                        <div className="flex flex-row items-center justify-end py-2 w-full space-x-6">
                                                            <Button onClick={() => { setIsExpanded(false); setSelectedIndex(false) }} type='link'>Cancel</Button>
                                                            <Button className='' type='primary' onClick={() => DFUResolve()}>Resolve</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
            {
                unResPop &&

                <UnresolvedPopUp />
            }
            {
                LFUResolvePop &&
                <ResolveLFUPopUp toggleExpandRow={toggleExpandRow} dcDFUIndex={dcDFUIndex}/>
            }
        </div >
    );
}

export default AlertsTable;
