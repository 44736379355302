import React, { useEffect, useState } from 'react';
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';
import { Button, Checkbox } from 'antd';
import { useBase } from '../Layout/baseContext/BaseContext';
import { FiSearch } from 'react-icons/fi';

const FilterInATTPopUp = () => {
    const { setinATTFilterPopUp, filterATT,
        filters, setFilters,
        subSelectionFilterMap, setSubSelectionFilterMap,
        checkBoxArray, setCheckBoxArray,
        submitFilters,
        clearAllFilters
    } = usePlan();
    const { uiMappingData, locationFilter, customerFilter, productFilter } = useBase();

    const [searchQuery, setSearchQuery] = useState('');
    const [filerItems, setfilerItems] = useState([]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const newMap = new Map();

        // Ensure that filters are populated and debug log their content


        if (productFilter && customerFilter && locationFilter) {
            if (Object.keys(productFilter).length !== 0 && Object.keys(customerFilter).length !== 0 && Object.keys(locationFilter).length !== 0) {
                if (filterATT && filterATT.charAt(0) === 'I') {
                    Object.keys(productFilter[0] || {}).forEach((item) => {
                        newMap.set(item, Array.from(new Set(productFilter.map((data) => data[item]))));
                    });
                } else if (filterATT && filterATT.charAt(0) === 'L') {
                    Object.keys(locationFilter[0] || {}).forEach((item) => {
                        newMap.set(item, Array.from(new Set(locationFilter.map((data) => data[item]))));
                    });
                } else if (filterATT && filterATT.charAt(0) === 'C') {
                    Object.keys(customerFilter[0] || {}).forEach((item) => {
                        newMap.set(item, Array.from(new Set(customerFilter.map((data) => data[item]))));
                    });
                }

                setSubSelectionFilterMap(newMap);
            }
        }
    }, [productFilter, customerFilter, locationFilter, filterATT, setSubSelectionFilterMap]);

    useEffect(() => {
        if (filterATT && subSelectionFilterMap.has(filterATT)) {
            const filteredItems = subSelectionFilterMap.get(filterATT).filter((item) => item.toLowerCase().includes(searchQuery.toLowerCase()));
            setfilerItems(filteredItems);
        } else {
            setfilerItems([]);
        }
    }, [searchQuery, filterATT, subSelectionFilterMap]);

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 text-bodyTextBlue">
            <div className="bg-white p-7 rounded-lg box-shadow text-center border border-slate-100 transform transition-transform duration-100 ease-out animate-scale-up">
                <h1 className="text-xl font-semibold mb-4">{uiMappingData[0][filterATT]}</h1>
                <div className='bg-slate-100 flex items-center rounded-md mb-4 px-3 py-1 mt-2'>
                    <div className='text-secondary'>
                        <FiSearch />
                    </div>
                    <input
                        className='bg-slate-100 outline-none pl-2'
                        type="text"
                        name="Search"
                        placeholder='search'
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                </div>
                <div className='min-h-60 max-h-60 mb-4 overflow-y-scroll scroll flex flex-col'>
                    {filerItems.map((item) => (
                        <Checkbox
                            key={item}
                            onChange={(e) => {
                                var updatedMap;
                                var arr = [];
                                if (e.target.checked) {
                                    updatedMap = filters;
                                    arr = filters.get(filterATT) ? filters.get(filterATT) : []
                                    arr.push(item)
                                    updatedMap.set(filterATT, arr)
                                    setCheckBoxArray((prevState) => [...prevState, item]);
                                    setFilters(updatedMap);
                                }
                                else {
                                    updatedMap = filters;
                                    arr = filters.get(filterATT) ? filters.get(filterATT) : []
                                    var newArr = arr.filter((data) => data !== item)
                                    updatedMap.set(filterATT, newArr)
                                    setCheckBoxArray((prevState) => prevState.filter((data) => data !== item));
                                    setFilters(updatedMap);
                                }
                            }}
                            checked={checkBoxArray.includes(item)}
                        >
                            {item}
                        </Checkbox>
                    ))}
                </div>
                <div className='flex justify-end gap-2'>
                    <Button type='link' onClick={() => setinATTFilterPopUp(false)}>Cancel</Button>
                    <Button type='primary' onClick={() => {setinATTFilterPopUp(false); submitFilters()}}>Apply</Button>
                </div>
            </div>
        </div>
    );
};

export default FilterInATTPopUp;
