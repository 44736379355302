import React from 'react';
import snopData from '../../data/snopData.json';
import FilterButton from '../filterButton';
import { FilterOutlined } from '@ant-design/icons';
import { useBase } from '../Layout/baseContext/BaseContext';
import { LuFilterX } from 'react-icons/lu';

const Snop = ({
    filterString, setFilterString, locationFilters, productFilters, customerFilters, uiMappingData
}) => {
    // Extract headers from the first row of the data
   
    const { setFields } = useBase()

    const clearHandler = () => {
        const storedFields = JSON.parse(localStorage.getItem('fields'));
    
        if (storedFields) {
          setFields(prevFields => ({
            ...prevFields,
            item: storedFields.item,
            location: storedFields.location,
            category: storedFields.category
          }));
        }
    
        setFilterString("")
      }

    return (
        <div className='flex flex-col w-full min-h-screen text-xs px-4 text-bodyTextBlue pb-3 '>
            <div className='py-4 pr-4 flex gap-4 justify-between '>
                <h1 className='font-bold text-lg'>Sales and Operations Planning</h1>
                <div className="flex items-center gap-x-2">

                    {filterString !== "" ? <div className='bg-slate-200 flex flex-row px-3 py-1 rounded-full text-red-500 cursor-pointer items-center hover:bg-slate-300' onClick={() => clearHandler()} ><span className="flex items-center gap-x-1"><span className="text-lg"><LuFilterX /></span>Clear Filters</span> </div> : null}

                    <FilterButton filterString={filterString} setFilterString={setFilterString} locationFilters={locationFilters} productFilters={productFilters} customerFilters={customerFilters} uiMappingData={uiMappingData !== null ? uiMappingData : null}>{<div><FilterOutlined /> Filter</div>}</FilterButton>
                </div>
            </div>
            <div className='relative h-full w-full '>
                <div className='absolute overflow-y-scroll scroll h-full w-full  border'>
                    <div className='table-sales flex flex-col w-full'>
                        <table className="table-auto w-full border-collapse bg-white">
                            <thead>
                                <tr className='bg-slate-200'>
                                    {snopData.columns.map((header, index) => (
                                        <th key={index} className=" px-2 py-2 shadow-col sticky top-0 z-10 text-left bg-slate-200">{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {snopData.rows.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {Object.values(row).map((header, index) => (
                                            <td key={index} className="border px-2 py-1">
                                                {header}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Snop;
