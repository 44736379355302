import { Button, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';

const ExpandCheckboxList = ({ Expandchecklist, setExpandchecklist }) => {
    // Temporary state for handling checkbox changes before applying them
    const [tempChecklist, setTempChecklist] = useState(Expandchecklist);

    // Sync tempChecklist with Expandchecklist whenever Expandchecklist changes
    useEffect(() => {
        setTempChecklist(Expandchecklist);
    }, [Expandchecklist]);

    // Handle checkbox change and update the temporary checklist
    const handleCheckboxChange = (item) => {
        setTempChecklist((prevListItems) => ({
            ...prevListItems,
            [item]: !prevListItems[item], // Toggle the checkbox state
        }));
    };

    // Handle click on the "Show" button to update the actual Expandchecklist
    const handleShowClick = () => {
        setExpandchecklist(tempChecklist);
    };

    return (
        <div className='flex gap-3 text-xs px-3 font-semibold items-center'>
            <div className='pr-2'>
                {/* Update the Expandchecklist only when "Show" button is clicked */}
                <Button
                    type='primary border-blue-500 !p-2 h-6'
                    onClick={handleShowClick}
                >
                    Show
                </Button>
            </div>
            <div className='overflow-x-scroll max-w-[84vw] flex gap-3 py-3'>
                {/* Use the temporary state to reflect the checkbox changes */}
                {Object.keys(tempChecklist).map((item) => (
                    <div key={item} className="flex items-center gap-2">
                        <Checkbox
                            checked={tempChecklist[item]} // Temporary state used here
                            onChange={() => handleCheckboxChange(item)}
                        >
                            {/* Conditionally apply text color based on the temporary state */}
                            <span className={`text-xs ${tempChecklist[item] ? 'text-blue-500' : 'text-bodyTextBlue'}`}>
                                {item}
                            </span>
                        </Checkbox>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExpandCheckboxList;
