import React, { useState } from 'react'
import UploadDoc from './UploadDoc'
import FirstUploadPage from './FirstUploadPage'
import Configure from './Configure'


const UploadTab = () => {
    const [firstPage, setFirstPage] = useState(true)
    const [configurePage, setConfigurePage] = useState(false)
  return (
    <>
    {
      firstPage ?
        <FirstUploadPage setFirstPage={setFirstPage} />
        :
        configurePage ?
          <Configure  setConfigurePage={setConfigurePage} />
          :
          <UploadDoc setConfigurePage={setConfigurePage} setFirstPage={setFirstPage} />
    }
  </>  )
}

export default UploadTab