import React, { createContext, useState, useContext, useEffect } from 'react';

const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
    const categories = {
        'Master Data': ['Item', 'Location', 'Customer', 'Calendars (Holiday, Shipment)'],
        'Transactions': ['Sales', 'Orders', 'Shipments', 'Confirmed Orders', 'Open Orders', 'Past Due Orders', 'Point Of Sale', 'Sell Out', 'Inventory'],
        'Initiatives': ['Price', 'Promotions', 'Events', 'CRM', 'Quotes', 'Proposals'],
        'External': ['Weather', 'Indexes']
    };

    const [selectedCategory, setselectedCategory] = useState(null);
    const [configBox, setConfigBox] = useState(null)
    const [selectedArea, setSelectedArea] = useState("uploadTab");
    const [tabList, setTabList] = useState(["upload", "params", "uiMapping"])
    return (
        <UploadContext.Provider
            value={{ categories,
                selectedCategory,setselectedCategory,
                configBox, setConfigBox,
                selectedArea, setSelectedArea,
                tabList, setTabList
                }}>
            {children}
        </UploadContext.Provider>
    );
};

export const useUpload = () => useContext(UploadContext);