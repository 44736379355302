import { Button } from 'antd'
import React from 'react'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { RxLightningBolt } from 'react-icons/rx'

const ForecastHelper = () => {
    return (
        <div className='bg-gray-100 h-full p-4 rounded-lg flex flex-col'>
            <h1 className='flex items-center text-lg font-bold'>
                <span className='text-2xl pr-1'>
                    <HiOutlineLightBulb />
                </span>
                <span>
                    Forecast Helper
                </span>
            </h1>
            <div className='flex flex-col gap-3 mt-3 overflow-y-scroll h-full pr-2 '>
                <div className='flex-col justify-between items-center bg-white rounded-md p-3  xl:flex xl:flex-row'>
                    <div className='flex items-center'>
                        <div className='text-xl pr-2'>
                            <RxLightningBolt />
                        </div> 
                        <div>
                            <p>Lorem ipsum dolor sit  adipisicing elit. Nemo dicta modi nisi eveniet quaerat veniam quisquam reprehenderit iusto cum veritatis!</p>
                        </div>
                    </div>
                    <div>
                        <Button type='link'><p className='underline pl-3 xl:pl-0'>Populate Values</p></Button>
                    </div>
                </div>
                <div className='flex-col justify-between items-center bg-white rounded-md p-3  xl:flex xl:flex-row'>
                    <div className='flex items-center'>
                        <div className='text-xl pr-2'>
                            <RxLightningBolt />
                        </div> 
                        <div>
                            <p>Lorem ipsum dolor sit  adipisicing elit. Nemo dicta modi nisi eveniet quaerat veniam quisquam reprehenderit iusto cum veritatis!</p>
                        </div>
                    </div>
                    <div>
                        <Button type='link'><p className='underline pl-3 xl:pl-0'>Populate Values</p></Button>
                    </div>
                </div>
                <div className='flex-col justify-between items-center bg-white rounded-md p-3  xl:flex xl:flex-row'>
                    <div className='flex items-center'>
                        <div className='text-xl pr-2'>
                            <RxLightningBolt />
                        </div> 
                        <div>
                            <p>Lorem ipsum dolor sit  adipisicing elit. Nemo dicta modi nisi eveniet quaerat veniam quisquam reprehenderit iusto cum veritatis!</p>
                        </div>
                    </div>
                    <div>
                        <Button type='link'><p className='underline pl-3 xl:pl-0'>Populate Values</p></Button>
                    </div>
                </div>
               
               
                
            </div>
        </div>
    )
}

export default ForecastHelper