import React from 'react'
import postDefaultLogo from "../../../components/assets/img/postDefaultLogo.png"
import { usePortfolio } from '../../Layout/baseContext/portfolioContext/PortfolioContext'


const PortListItem = ({ item }) => {
  const { setSelectedListItem, selectedChip, setDisContinueForm, setOldTransitionForm } = usePortfolio()

  const handleListItemClick = () => {
    setSelectedListItem(item)
    setDisContinueForm(false)
    setOldTransitionForm(false)
  }
  return (
    <button className='w-full' onClick={handleListItemClick}>
      <div className='bg-white shadow-[rgba(203,213,225,0.5)_0px_0px_20px] py-2 px-3 rounded-xl flex gap-3 items-center  hover:scale-105 transition-all duration-100'>
        <div>
          <img src={postDefaultLogo} className='rounded-lg' width={30} alt="" />
        </div>
        <div className='font-semibold text-xs w-full flex justify-between items-center'>
          <div className='w-full text-start capitalize'>
            <p>
              {
                selectedChip === 'products' &&
                item.ITEM
              }
              {
                selectedChip === 'customers' &&
                item.CUSTOMER
              }
              {
                selectedChip === 'locations' &&
                item.LOCATION
              }
            </p>
            <p className='font-normal text-slate-400'>{item.SOURCE}</p>
          </div>
          <div>

          </div>
        </div>
      </div>
    </button>
  )
}

export default PortListItem