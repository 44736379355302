import React from 'react'
import Errors from './Errors'
import OverallRevenue from './OverallRevenue'
import TeamAndUpdates from './TeamAndUpdates'
import PendingActions from './PendingActions'
import ActivityHistory from './ActivityHistory'
import Insight from './Insight'

const Dashboard = () => {
    return (
        <div className='flex flex-col w-full min-h-screen  overflow-y-scroll text-xs px-4 pb-4 text-bodyTextBlue '>
            <div className='py-2'>
                <h1 className='font-bold text-base'>Hello John, Welcome back</h1>
            </div>
            <div className='flex flex-row w-full gap-2 h-full '>
                <div className='flex flex-col w-[75%] gap-2 '>
                    <div className=''>
                        <Errors />
                    </div>
                    <div className='rounded-xl flex flex-row gap-2'>
                        <div className='w-3/4 rounded-xl bg-white'>
                            <OverallRevenue />
                        </div>
                        <div className='w-1/4 rounded-xl bg-white'>
                            <Insight />
                        </div>
                    </div>
                    <div className='bg-white rounded-xl h-full'>
                        <TeamAndUpdates />
                    </div>
                </div>
                <div className='flex flex-col w-[25%]   gap-2 '>
                    <div className='bg-white rounded-xl  '>
                        <PendingActions />
                    </div>
                    <div className='bg-white rounded-xl h-full'>
                        <ActivityHistory />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard