import { Input, Select, Button, message } from 'antd';
import React, { useState } from 'react';

const ConfigForm = () => {
    const [formData, setFormData] = useState({
        inbound: {},
        outbound: {},
        schedule: {},
    });

    const handleSelectChange = (section, field, value) => {
        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const handleInputChange = (section, field, event) => {
        const { value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const handleReset = () => {
        setFormData({
            inbound: {},
            outbound: {},
            schedule: {},
        });
        message.info('Form has been reset');
    };

    const handleSubmit = () => {
        console.log('Submitted Form Data:', formData);
        message.success('Form submitted successfully');
    };

    const locationOptions = [
        { value: 'local', label: 'Local Directory' },
        { value: 'url', label: 'URL' },
        { value: 'cloud', label: 'Cloud Storage' },
    ];

    return (
        <div className='flex flex-col h-full w-full gap-6'>
            <div className='grid grid-cols-3 gap-4 w-full h-full'>
                {/* Inbound Section */}
                <div className='flex flex-col bg-white p-5 shadow-sm border rounded-md'>
                    <h3 className='text-sm font-semibold mb-2'>Inbound</h3>
                    <div className='flex flex-col h-full gap-2 w-full'>
                        {/* File Source */}
                        <div className='flex flex-col gap-1 justify-between w-full'>
                            <label htmlFor="fileSource">File Source</label>
                            <Select
                                className='config-select'
                                placeholder="Select source"
                                style={{ height: '2.5rem', borderRadius: '2rem', margin: '0' }}
                                value={formData.inbound.fileSource}
                                onChange={(value) => handleSelectChange('inbound', 'fileSource', value)}
                                options={locationOptions}
                            />
                        </div>

                        {/* File Location */}
                        <div className='flex flex-col gap-1 justify-between w-full'>
                            <label htmlFor="fileLocation">Source File Path</label>
                            <Input
                                id="fileLocation"
                                placeholder="Enter file path"
                                className='rounded-md bg-slate-100 py-2 border-blue-100'
                                value={formData.inbound.fileLocation}
                                onChange={(e) => handleInputChange('inbound', 'fileLocation', e)}
                            />
                        </div>

                        {/* Archive Location */}
                        <div className='flex flex-col gap-1 justify-between w-full'>
                            <label htmlFor="archiveLocation">Archive Location</label>
                            <Input
                                id="archiveLocation"
                                placeholder="Enter archive location"
                                className='rounded-md bg-slate-100 py-2 border-blue-100'
                                value={formData.inbound.archiveLocation}
                                onChange={(e) => handleInputChange('inbound', 'archiveLocation', e)}
                            />
                        </div>

                        {/* Time Zone */}
                        <div className='flex flex-col gap-1 justify-between w-full'>
                            <label htmlFor="timeZone">Time Zone</label>
                            <Select
                                className='config-select'
                                placeholder="Select time zone"
                                style={{ height: '2.5rem', borderRadius: '2rem', margin: '0' }}
                                value={formData.inbound.timeZone}
                                onChange={(value) => handleSelectChange('inbound', 'timeZone', value)}
                                options={[
                                    { value: 'UTC', label: 'UTC' },
                                    { value: 'EST', label: 'EST (Eastern Standard Time)' },
                                    { value: 'PST', label: 'PST (Pacific Standard Time)' },
                                ]}
                            />
                        </div>
                    </div>
                </div>

                {/* Outbound Section */}
                <div className='flex flex-col h-full bg-white rounded-md  p-5 shadow-sm border'>
                    <h3 className='text-sm font-semibold mb-2'>Outbound</h3>
                    <div className='flex flex-col h-full gap-4 w-full'>
                        {/* File Destination */}
                        <div className='flex flex-col gap-1 justify-between w-full'>
                            <label htmlFor="fileDestination">File Destination</label>
                            <Select
                                className='config-select'
                                placeholder="Select destination"
                                style={{ height: '2.5rem', borderRadius: '2rem', margin: '0' }}
                                value={formData.outbound.fileDestination}
                                onChange={(value) => handleSelectChange('outbound', 'fileDestination', value)}
                                options={[
                                    { value: 'local', label: 'Local Directory' },
                                    { value: 'cloud', label: 'Cloud Storage' },
                                    { value: 'ftp', label: 'FTP Server' },
                                ]}
                            />
                        </div>

                        {/* File Destination Path */}
                        <div className='flex flex-col gap-1 justify-between w-full'>
                            <label htmlFor="fileDestinationLocation">Destination Path</label>
                            <Input
                                id="fileDestinationLocation"
                                placeholder="Enter destination path"
                                className='rounded-md bg-slate-100 py-2 border-blue-100'
                                value={formData.outbound.fileDestinationLocation}
                                onChange={(e) => handleInputChange('outbound', 'fileDestinationLocation', e)}
                            />
                        </div>

                        {/* File Type */}
                        <div className='flex flex-col gap-1 justify-between w-full'>
                            <label htmlFor="fileType">File Type</label>
                            <Select
                                className='config-select'
                                placeholder="Select file type"
                                style={{ height: '2.5rem', borderRadius: '2rem', margin: '0' }}
                                value={formData.outbound.fileType}
                                onChange={(value) => handleSelectChange('outbound', 'fileType', value)}
                                options={[
                                    { value: 'csv', label: 'CSV' },
                                    { value: 'json', label: 'JSON' },
                                    { value: 'xml', label: 'XML' },
                                ]}
                            />
                        </div>
                    </div>
                </div>

                {/* Schedule Section */}
                <div className='flex flex-col h-full bg-white rounded-md p-5 shadow-sm border'>
                    <h3 className='text-sm font-semibold mb-2'>Schedule</h3>
                    <div className='flex flex-col h-full gap-4 w-full'>
                        {/* Schedule Type */}
                        <div className='flex flex-col gap-1 justify-between w-full'>
                            <label htmlFor="scheduleType">Schedule Type</label>
                            <Select
                                className='config-select'
                                placeholder="Select schedule type"
                                style={{ height: '2.5rem', borderRadius: '2rem', margin: '0' }}
                                value={formData.schedule.scheduleType}
                                onChange={(value) => handleSelectChange('schedule', 'scheduleType', value)}
                                options={[
                                    { value: 'daily', label: 'Daily' },
                                    { value: 'weekly', label: 'Weekly' },
                                    { value: 'monthly', label: 'Monthly' },
                                ]}
                            />
                        </div>

                        {/* Standard Cron */}
                        <div className='flex flex-col gap-1 justify-between w-full'>
                            <label htmlFor="cron">Standard Cron Expression</label>
                            <Input
                                id="cron"
                                placeholder="Enter cron expression"
                                className='rounded-md bg-slate-100 py-2 border-blue-100'
                                value={formData.schedule.cron}
                                onChange={(e) => handleInputChange('schedule', 'cron', e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex gap-3'>
                <Button type='default' onClick={handleReset}>Reset all</Button>
                <Button type='primary' onClick={handleSubmit}>Submit</Button>
            </div>
        </div>
    );
};

export default ConfigForm;
