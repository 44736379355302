import React, { useState, useEffect } from 'react';
import { useQues } from '../context/QuesContext';
import { Button, Input, Steps } from 'antd';

const QuesBox = ({ questions, category }) => {
    const { TextArea } = Input;
    const { setShowQues } = useQues();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [responses, setResponses] = useState(() => {
        const savedResponses = localStorage.getItem('responses');
        return savedResponses ? JSON.parse(savedResponses) : {};
    });

    useEffect(() => {
        const firstUnansweredIndex = getFirstUnansweredQuestionIndex();
        setCurrentIndex(firstUnansweredIndex);
    }, []);

    useEffect(() => {
        localStorage.setItem('responses', JSON.stringify(responses));
    }, [responses]);

    const getFirstUnansweredQuestionIndex = () => {
        const categoryResponses = responses[category] || {};
        for (let i = 0; i < questions.length; i++) {
            if (!categoryResponses[questions[i]]) {
                return i;
            }
        }
        return questions.length-1; // Default to the first question if all are answered
    };

    const handleNext = () => {
        if (currentIndex < questions.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleSkip = () => {
        if (currentIndex < questions.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const onChange = (value) => {
        setCurrentIndex(value);
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleSubmit = () => {
        console.log("Submit", responses);
        setShowQues(false);
    };

    const handleCancel = () => {
        setShowQues(false);
    };

    const handleResponseChange = (e) => {
        const newResponses = { ...responses };
        if (!newResponses[category]) {
            newResponses[category] = {};
        }
        newResponses[category][questions[currentIndex]] = e.target.value;
        setResponses(newResponses);
    };

    const getStatus = (index) => {
        const categoryResponses = responses[category] || {};
        return categoryResponses[questions[index]] ? 'finish' : 'wait';
    };

    return (
        <div className='fixed w-full z-50 h-full top-0 left-8 flex justify-center items-center '>
            <div className='w-[50vw] h-[60vh] bg-white p-6 shadow-[rgba(70,70,70)_0px_0px_200px] rounded-lg'>
                <div className='flex flex-col h-full justify-between'>
                    <div className='flex flex-col'>
                        <div className='font-semibold text-lg '>
                            <h3>{category}</h3>
                        </div>
                        <Steps current={currentIndex} onChange={onChange} className='mt-4 custom-steps'>
                            {questions.map((question, index) => (
                                <Steps.Step key={index} status={getStatus(index)} />
                            ))}
                        </Steps>
                        <div className='flex flex-col gap-2 mt-4'>
                            <div>
                                <span className='text-base'>{currentIndex + 1}.</span>
                                <span className='text-base pl-1'>{questions[currentIndex]}</span>
                            </div>
                            <div>
                                <TextArea
                                    style={{ resize: 'none' }}
                                    rows={6}
                                    value={responses[category]?.[questions[currentIndex]] || ''}
                                    onChange={handleResponseChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mt-4'>
                        <div>
                            <Button className='bg-slate-300 text-black hover:!bg-slate-400' type="primary" onClick={handleCancel}>Cancel</Button>
                        </div>
                        <div className='flex gap-2'>
                            {currentIndex > 0 && (
                                <Button onClick={handlePrevious}>Previous</Button>
                            )}
                            {currentIndex < questions.length - 1 ? (
                                <>
                                    {/* <Button onClick={handleSkip}>Skip</Button> */}
                                    <Button type="primary" onClick={handleNext}>Next</Button>
                                </>
                            ) : (
                                <Button type='primary' onClick={handleSubmit}>Submit</Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuesBox;
