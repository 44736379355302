import React from 'react'
import Ques from './components/Ques'
import { QuesProvider } from './context/QuesContext'

const QuesContainer = () => {
  return (
    <QuesProvider>
      <Ques/>
    </QuesProvider>
  )
}

export default QuesContainer