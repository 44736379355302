import React from 'react'
import postDefaultLogo from "../../../components/assets/img/postDefaultLogo.png"
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import { usePost } from '../../Layout/baseContext/postContext/PostContext';


const PostListItem = ({ post }) => {
  const {selectedPost, setSelectedPost,setDiscussion} = usePost();
  const handleClick = () => {
    setDiscussion(false)
    setSelectedPost(post)
  }

  return (
    <button onClick={handleClick} className='w-full'>
      <div className='bg-white shadow-[rgba(203,213,225,0.5)_0px_0px_20px] py-2 px-3 rounded-xl flex gap-3 items-center  hover:scale-105 transition-all duration-100'>
        <div>
          <img src={postDefaultLogo} className='rounded-lg' width={30} alt="" />
        </div>
        <div className='font-semibold text-xs w-full flex justify-between items-center'>
          <div className='w-full text-start'>
            <p>{`${post.id}-${post.ITEM_VALUE}-${post.LOCATION_VALUE}-${post.CUSTOMER_VALUE}`}</p>
            <p className='font-normal text-slate-400'>{`${post.BY_USER}`}</p>
          </div>

          {
            post.NEW_VALUE > post.PREVIOUS_VALUE &&
            <div className='text-green-500'>
              <HiArrowNarrowUp />
            </div>
          }
          {post.NEW_VALUE < post.PREVIOUS_VALUE &&
            <div className='text-red-500'>
              <HiArrowNarrowDown />
            </div>
          }
          <div>

          </div>
        </div>
      </div>
    </button>
  )
}

export default PostListItem