import React from 'react'
import PostList from '../components/PostList'
import SelectedPost from '../components/SelectedPost'
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import Discussion from './Discussion';
import { TailSpin } from 'react-loader-spinner';


const Posts = () => {
  // const [discussion, setDiscussion] = useState(false);
  const { loading, discussion, setDiscussion, selectedPost} = usePost();
  

  return (
    <div className='bg-background h-full w-full '>
      <div className='flex w-full h-full'>
        {
          loading ? 
          (<div className='h-full w-full flex justify-center items-center'><TailSpin
          visible={true}
          height="40"
          width="40"
          color="#2B3674"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          /></div>)
          :
          (<div className="w-full h-full overflow-x-hidden ">
          {
            discussion ?
              (
                <div className=' pb-[2px] h-full '>
                  {
                    loading ? 
                    (<div>loading</div>)
                    :
                    ( <Discussion setDiscussion={setDiscussion} />)
                  }
                 
                </div>
              )
              :
              (
                <div className='w-full flex gap-4 h-full '>
                  <div className='basis-[19rem]  pb-[2px] shrink-0 h-full '>
                    <PostList />
                  </div>
                  <div className='w-full pb-[2px] h-full '>
                   {
                     selectedPost && <SelectedPost  />

                   }
                  </div>
                </div>
              )
          }

      </div>)
        }
        
      </div>
    </div>

  )
}

export default Posts