import React from 'react'
import { useDashboard } from '../../Layout/baseContext/dashboardContext/DashboardContext'
import ActivityHistory from './ActivityHistory';
import { Button } from 'antd';

const SeeAll = () => {
  const { showSeeAll,setShowSeeAll } = useDashboard();
  return (
    <div className='fixed w-full z-50 h-full top-0 left-8 flex justify-center items-center text-xs text-bodyTextBlue'>
      <div className='w-[80vw] h-[80vh] bg-white px-2 shadow-[rgba(70,70,70)_0px_0px_200px] rounded-lg'>
        <div className='flex flex-col pb-4 h-full w-full'>

          {
            showSeeAll === 'activity' &&
            <ActivityHistory />
          }
          <div className='flex justify-end pr-2'>
            <Button onClick={() => setShowSeeAll("")} className='' type='primary'>Close</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SeeAll