import { List } from 'antd'
import React from 'react'
// import { PortfolioProvider, usePortfolio } from '../Layout/baseContext/portfolioContext/PortfolioContext'
import Portfolio from './pages/Portfolio';

const PortfolioContainer = ({ filterString, setFilterString, locationFilters, productFilters, customerFilters }) => {
  
  return (
  
      <Portfolio locationFilters={locationFilters}
      productFilters={productFilters}
      customerFilters={customerFilters} />
  )
}

export default PortfolioContainer