import React, { useState } from 'react'
import { usePortfolio } from '../../Layout/baseContext/portfolioContext/PortfolioContext';
import PortList from '../components/PortList';
// import DataSection from '../components/DataSection';
import PortForm from '../components/PortForm';
import { TailSpin } from 'react-loader-spinner';

const Portfolio = () => {
  const { selectedChip, setSelectedChip, dataChanger, loading } = usePortfolio();
  return (
    <div className='h-full '>
      {
        loading ?
          (<div className='h-full flex justify-center items-center'><TailSpin
            visible={true}
            height="40"
            width="40"
            color="#2B3674"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          /></div>)
          :
          <>
            {/* <div className='flex bg-white w-fit rounded-full gap-2 p-[0.35rem] shadow-sm text-gray-400'>
              <button className={`${selectedChip === "products" ? "bg-secondary text-white rounded-full " : "hover:text-secondary"} px-2 py-1 flex-1`} onClick={() => { setSelectedChip("products"); dataChanger('products') }}>Products</button>
              <button className={`${selectedChip === "customers" ? "bg-secondary text-white rounded-full " : "hover:text-secondary"} px-2 py-1 flex-1`} onClick={() => { setSelectedChip("customers"); dataChanger('customers') }}>Customers</button>
              <button className={`${selectedChip === "locations" ? "bg-secondary text-white rounded-full " : "hover:text-secondary"} px-2 py-1 flex-1`} onClick={() => { setSelectedChip("locations"); dataChanger('locations') }}>Locations</button>
            </div> */}
            <div className='h-full flex'>
              <div className='h-full basis-[19rem] shrink-0'>
                
                <PortList />
              </div>
              <div className='w-full h-full bg-white ml-3 rounded-2xl shadow-sm'>
                <PortForm />
              </div>
            </div>
          </>

      }

    </div>
  )
}

export default Portfolio