import { Button } from 'antd';
import React, { useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useBase } from '../../../Layout/baseContext/BaseContext';

const ParamTable = ({ selectedLabel, setSelectedLabel }) => {
  const { params, setParams } = useBase(); // Assuming setParams can be used to update the params.
  const [editCol, setEditCol] = useState(false);
  const [modifiedValue, setModifiedValue] = useState({}); // To store the modified USER_MODIFIED_VALUE for each row

  const tableData = params.filter((data) => data.LABEL === selectedLabel);

  const updateParams = (paramData, modifiedValues) => {
    return paramData.map(param => {
      if (modifiedValues[param.PARAM_CODE]) {
        return {
          ...param,
          USER_MODIFIED_VALUE: modifiedValues[param.PARAM_CODE]
        };
      }
      return param;
    });
  };

  const handleInputChange = (PARAM_CODE, value) => {
    setModifiedValue((prevState) => ({
      ...prevState,
      [PARAM_CODE]: value, // Updating the value for the specific row's id
    }));
  };

  const handleSubmit = () => {
    const updatedParams = updateParams(params, modifiedValue);
    setParams(updatedParams);
    setEditCol(false); // Disable edit mode after saving
  };

  return (
    <div className='w-full h-full flex flex-col'>
      {/* Go back button */}
      <div className='flex gap-4 items-center'>
        <Button
          onClick={() => setSelectedLabel(null)}
          className='p-0 m-0 h-0 text-base text-slate-400'
          type='link'
        >
          <IoMdArrowRoundBack /> Go back
        </Button>
        <p className='text-base font-semibold'>{selectedLabel}</p>
      </div>

      {/* Table rendering */}
      {tableData.length > 0 ? (
        <div className='mt-4 flex flex-col h-full w-full'>
          {/* Header */}
          <header className='flex w-[calc(100%-5px)]'>
            {Object.keys(tableData[0]).map((key) => {
              if (key !== 'id' && key !== 'LABEL' && key !== 'USER_MODIFIED_VALUE') {
                return (
                  <p key={key} className='px-4 py-2 bg-slate-100 border w-full font-semibold text-sm overflow-hidden'>
                    {key}
                  </p>
                );
              }
              if (key === 'USER_MODIFIED_VALUE') {
                return (
                  <div key={key} className='px-4 py-2 flex justify-between items-center bg-slate-100 border w-full font-semibold text-sm overflow-hidden'>
                    <p>{key}</p>
                    {editCol ? (
                      <Button onClick={handleSubmit} className='h-0 p-0' type='link'>Save</Button>
                    ) : (
                      <Button onClick={() => setEditCol(true)} className='h-0 p-0' type='link'>Edit</Button>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </header>

          {/* Table Rows */}
          <div className='relative h-full w-full'>
            <div className='absolute overflow-y-scroll scroll h-full w-full border'>
              <div className='flex flex-col h-full  w-full'>
                {tableData.map((data, index) => (
                  <div key={index} className='flex w-full'>
                    {Object.entries(data).map(([key, value]) => {
                      if (key !== 'id' && key !== 'LABEL' && key !== 'USER_MODIFIED_VALUE') {
                        return (
                          <p key={key} className='px-4 py-2 bg-white border w-full overflow-hidden'>
                            {value}
                          </p>
                        );
                      }

                      if (key === 'USER_MODIFIED_VALUE') {
                        return editCol ? (
                          <input
                            key={key}
                            type="text"
                            className='px-4 py-2 w-full border bg-gray-100 overflow-hidden'
                            value={modifiedValue[data.PARAM_CODE] !== undefined ? modifiedValue[data.PARAM_CODE] : value} // Use empty string if cleared
                            onChange={(e) => handleInputChange(data.PARAM_CODE, e.target.value)} // Capture changes
                          />
                        ) : (
                          <p key={key} className='px-4 py-2 bg-white border w-full overflow-hidden'>
                            {value}
                          </p>
                        );
                      }
                      return null;
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>No data available for the selected label</p>
      )}
    </div>
  );
};

export default ParamTable;
