import React from 'react';
import { Line } from 'react-chartjs-2';
import { useAlert } from '../../Layout/baseContext/alertsContext/AlertsContext';

const DFUGraph = () => {
    const { DFUFullData, showDFUGraph, setShowDFUGraph } = useAlert(); // Add setShowDFUGraph to update the state

    // Filter the data based on DFU from showDFUGraph
    const filteredData = DFUFullData.filter(item => item.DFU === showDFUGraph.dfu);

    // Extract dates (SDATE) and sqty values (SQTY_LFU) from the filtered data
    const dates = filteredData.map(entry => entry.SDATE);
    const sqtyValues = filteredData.map(entry => entry.SQTY_LFU);

    // Customize point styles based on showDFUGraph.sdate and comparison between SQTY_LFU and SQTY_CORRECTED_ENGINE
    const pointBackgroundColors = filteredData.map(entry => {
        if (entry.SDATE === showDFUGraph.sdate) {
            return '#ef4444'; // Red for the highlighted date from showDFUGraph
        }
        if (entry.SQTY_LFU > entry.SQTY_CORRECTED_ENGINE) {
            return '#f97316'; // Orange if SQTY_LFU is greater than SQTY_CORRECTED_ENGINE
        }
        if (entry.SQTY_LFU < entry.SQTY_CORRECTED_ENGINE) {
            return '#22c55e'; // Green if SQTY_LFU is less than SQTY_CORRECTED_ENGINE
        }
        return '#ffdb5c'; // Default color for other points
    });

    const pointBorderColors = filteredData.map(entry => {
        if (entry.SDATE === showDFUGraph.sdate) {
            return '#ef4444'; // Red for the highlighted date from showDFUGraph
        }
        if (entry.SQTY_LFU > entry.SQTY_CORRECTED_ENGINE) {
            return '#f97316'; // Orange if SQTY_LFU is greater than SQTY_CORRECTED_ENGINE
        }
        if (entry.SQTY_LFU < entry.SQTY_CORRECTED_ENGINE) {
            return '#22c55e'; // Green if SQTY_LFU is less than SQTY_CORRECTED_ENGINE
        }
        return '#ffdb5c'; // Default color for other points
    });

    const pointRadii = filteredData.map(entry => 
        entry.SDATE === showDFUGraph.sdate ? 5 : 3 // Set point radius to 5 for the highlighted date, otherwise 3
    );

    const chartData = {
        labels: dates,
        datasets: [{
            label: '', // No label
            data: sqtyValues,
            borderColor: '#ffdb5c',
            backgroundColor: '#ffdb5c',
            borderWidth: 2,
            pointRadius: pointRadii, // Apply conditional point radii
            pointHoverRadius: 5,
            pointBackgroundColor: pointBackgroundColors, // Conditional background color for points
            pointBorderColor: pointBorderColors // Conditional border color for points
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: false,
                    text: 'SQTY'
                }
            },
            x: {
                grid: {
                    display: false
                },
                title: {
                    display: false,
                    text: 'Date'
                }
            }
        },
        plugins: {
            legend: {
                display: false // Hide the legend
            },
            tooltip: {
                mode: 'index',
                intersect: false
            }
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const chart = elements[0];
                const index = chart.index;
                const selectedDate = dates[index]; // Get the clicked date
                setShowDFUGraph(prevState => ({
                    ...prevState,
                    sdate: selectedDate // Update the sdate in showDFUGraph
                }));
            }
        }
    };

    return (
        <div className='bg-white p-3 rounded-xl border shadow-sm w-1/2'>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default DFUGraph;
