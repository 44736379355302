import React from 'react'
import { FiSearch } from "react-icons/fi";
import PostListItem from './PostListItem';
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import { useState } from 'react';
import { FiPlus } from "react-icons/fi";
import { RiErrorWarningLine } from "react-icons/ri";
import { TailSpin } from 'react-loader-spinner';


const PostList = () => {
    let { posts, loading } = usePost();

    
    
    const [selectedTab, setSelectedTab] = useState('ongoing')

    // var defaultPosts = posts.filter((post) => post.STATUS === 'ongoing')
    // 
    // const [filteredPosts, setFilteredPosts] = useState(defaultPosts)
    const [filteredPosts, setFilteredPosts] = useState(posts)
    // 
    
    // useEffect(() => {
    //     if(defaultPosts.length === 0){
    //         defaultPosts = posts.filter((post) => post.STATUS === 'archived')
    //         setFilteredPosts(defaultPosts)
    //         setSelectedTab('archived')
    //     }
    //     if(defaultPosts.length===0){
    //         defaultPosts = posts.filter((post) => post.STATUS === 'old')
    //         setFilteredPosts(defaultPosts)
    //         setSelectedTab('old')
    //     }
    // }, [])
    
    let ongoingCount = 0;
    let archiveCount = 0;
    let oldCount = 0;

   

    posts.forEach(post => {
        switch (post.STATUS) {
            case 'ongoing':
                ongoingCount++;
                break;
            case 'archived':
                archiveCount++;
                break;
            case 'old':
                oldCount++;
                break;
            default:
                break;
        }
    });

    


    const handleTabClick = (tab) => {
        setSelectedTab(tab)
        setFilteredPosts(() => posts = posts.filter((post) => tab === post.STATUS))
    }

    

    return (
        <div className='bg-white rounded-2xl shadow-sm text-bodyTextBlue h-full flex flex-col'>
            {
                loading ? 
                (<div className='h-full flex justify-center items-center'><TailSpin
                    visible={true}
                    height="40"
                    width="40"
                    color="#2B3674"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    /></div>)
                :
                (<>
                 <div className='relative  pt-3 mx-4'>
                <h2 className='font-bold'>
                    Posts/Conversations
                </h2>
                <p className='text-inactiveText text-xs font-semibold mt-1'>Showing <span className='text-secondary'>{posts.length}</span> posts from</p>
                <div className='flex gap-5 font-semibold text-xs mt-3'>
                    <button className='absolute right-1 top-3 text-xs bg-slate-100 text-primary p-1 rounded-md cursor-pointer hover:bg-slate-200'>
                        <FiPlus />
                    </button>
                    <button className='absolute bottom-2 right-1 text-xs bg-slate-100 text-secondary p-1 rounded-md cursor-pointer hover:bg-slate-200'>
                        <FiSearch />
                    </button>
                </div>
            </div>
            <div className='border-b flex justify-between text-xs mx-4 font-semibold'>
                <p className={` ${selectedTab === 'ongoing' ? 'border-b-2 border-bodyTextBlue text-bodyTextBlue' : 'text-inactiveText'}  w-[30%] text-center pb-1 cursor-pointer`} onClick={() => handleTabClick('ongoing')}>Ongoing ({ongoingCount})</p>
                <p className={` ${selectedTab === 'old' ? 'border-b-2  border-bodyTextBlue text-bodyTextBlue' : 'text-inactiveText'}  w-[30%] text-center pb-1 cursor-pointer`} onClick={() => handleTabClick('old')}>Old ({oldCount})</p>
                <p className={`${selectedTab === 'archived' ? 'border-b-2 border-bodyTextBlue text-bodyTextBlue' : 'text-inactiveText'}  w-[30%] text-center pb-1 cursor-pointer`} onClick={() => handleTabClick('archived')}>Archived ({archiveCount})</p>
            </div>
            <ul className='scroll flex flex-col gap-2 overflow-y-scroll p-3 h-full'>
                {
                    // filteredPosts.length === 0 ? 
                    posts.length === 0 ? 

                    (<div className='flex flex-col justify-center items-center mt-10 text-slate-400'>
                        <div className='text-4xl'>
                            <RiErrorWarningLine/>
                        </div>
                        <p className='font-bold'>No data found</p> 
                    </div>)
                    :
                    posts.map((post, index) => (
                    // filteredPosts.map((post, index) => (
                        <li className='w-full' key={index} >
                            <PostListItem post={post} />
                        </li>
                    ))
                    
                }
            </ul>
                </>)
            }
           
        </div>
    )
}

export default PostList