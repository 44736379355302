import React from 'react'
import { useAlert } from '../../Layout/baseContext/alertsContext/AlertsContext'
import { Button } from 'antd';

const ResolveLFUPopUp = ({toggleExpandRow,dcDFUIndex}) => {
    const { setLFUResolvePop} = useAlert();
  return (
    <div className="fixed inset-0 flex items-center justify-center  z-50">
            <div className="bg-white p-8 w-96 rounded-lg shadow-lg text-center transform transition-transform duration-300 ease-out animate-scale-up">
                <h1 className="text-xl font-semibold mb-2">This DFU contains positive and negative Resolved Quantities</h1>
                <div className='flex justify-center gap-3 mt-4'>

                <Button type='link' onClick={() => setLFUResolvePop(false)}>Cancel</Button>
                <Button type='primary' onClick={() =>{toggleExpandRow(dcDFUIndex); setLFUResolvePop(false)}}>Resolve at LFU level</Button>
                </div>
            </div>
        </div>
  )
}

export default ResolveLFUPopUp