import React from 'react'
import { useAlert } from '../../Layout/baseContext/alertsContext/AlertsContext'
import { Button } from 'antd'

const UnresolvedPopUp = () => {
    const { setunResPop,handleDiscardChanges } = useAlert()
    return (
        <div className="fixed inset-0 flex items-center justify-center  z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition-transform duration-300 ease-out animate-scale-up">
                <h1 className="text-xl font-semibold mb-2">You have unsaved resolutions</h1>
                <p
                    className="text-base mb-8"

                >
                    Do you want to discard changes ?
                </p>
                <div className='flex justify-center gap-2 '>
                    <Button className='border border-blue-500' type='link' onClick={() => {setunResPop(false); handleDiscardChanges();}}>Yes</Button>
                    <Button type='primary' onClick={() => setunResPop(false)}>No</Button>
                </div>
            </div>
        </div>
    )
}

export default UnresolvedPopUp